import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import Up from "../Components/Assets/SVG/UpBtn";

const ScrollToTopButton = () => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const location = useLocation();

  // Routes where the button should be available
  const allowedRoutes = [
    "/",
    "/css",
    "/blog",
    "/blog/1",
    "/blog/2",
    "/blog/3",
    "/wallpaper-gallery"
  ];

  const checkScrollPosition = () => {
    // Show button when scrolled down more than 300px from the top
    // (This is a common pattern - only show when user has scrolled a bit)
    const scrolledDown = window.scrollY > 300;
    setShowScrollToTop(scrolledDown);
  };

  useEffect(() => {
    // Check scroll position when scrolling
    window.addEventListener("scroll", checkScrollPosition);
    
    // Initial check
    checkScrollPosition();

    return () => window.removeEventListener("scroll", checkScrollPosition);
  }, []);

  useEffect(() => {
    // When route changes, check if we should show the button
    // Reset to hidden on new page load
    setShowScrollToTop(false);
    
    // Small delay to check after page content is loaded
    const timer = setTimeout(() => {
      checkScrollPosition();
    }, 100);
    
    return () => clearTimeout(timer);
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // Only show on allowed routes
  if (!allowedRoutes.includes(location.pathname)) return null;

  // Animation variants with faster pop and mirrored entry/exit
  const buttonVariants = {
    hidden: { 
      opacity: 0,
      scale: 0
    },
    visible: { 
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.15,
        ease: [0.175, 0.885, 0.32, 1.275] // Custom cubic bezier for quick pop
      }
    },
    exit: {
      opacity: 0,
      scale: 0,
      transition: {
        duration: 0.15,
        ease: [0.6, -0.28, 0.735, 0.045] // Reverse of the entry ease
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      {showScrollToTop && (
        <motion.button
          className="scroll-to-top"
          onClick={scrollToTop}
          aria-label="Scroll to top of the page"
          initial="hidden"
          animate="visible"
          exit="exit"
          variants={buttonVariants}
          whileHover={{ scale: 1.1, transition: { duration: 0.1 } }}
          whileTap={{ scale: 0.9, transition: { duration: 0.05 } }}
        >
          <Up/>
        </motion.button>
      )}
    </AnimatePresence>
  );
};

export default ScrollToTopButton;