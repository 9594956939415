import React, { useState, useEffect, useCallback, useRef, memo } from "react";
import { LazyMotion, domAnimation, useInView } from "framer-motion";
import { useTheme } from "./../../Theme/Theme";

import "./CSStips.css";
import codepenLogo from "../Assets/codepen.svg";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Memoized smaller components
const Flake = memo(() => (
  <div className="Flake">
    <div className="stroke stroke-1"></div>
    <div className="stroke stroke-2"></div>
    <div className="stroke stroke-3"></div>
    <div className="stroke stroke-4"></div>
  </div>
));

const CodepenButton = memo(({ url, disabled = false }) => (
  <button
    className="codepenbtn"
    onClick={() => !disabled && window.open(url, "_blank")}
    disabled={disabled}
  >
    <img src={codepenLogo} className="codepenLogo" alt="codepenLogo" />
    Get the Code
  </button>
));

const Tags = memo(({ tags }) => (
  <div className="tags">
    {tags.includes("html") && <div className="html-tag">HTML</div>}
    {tags.includes("css") && <div className="css-tag">CSS</div>}
    {tags.includes("js") && <div className="js-tag">JS</div>}
  </div>
));

// Optimize TipItem component with useInView
const TipItem = memo(({ index, children, tags, codepenUrl }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });
  
  // Stagger the animation delay based on item index
  const delay = index * 0.1;
  
  const itemStyle = {
    transform: isInView ? "translateY(0)" : "translateY(20px)",
    opacity: isInView ? 1 : 0,
    transition: `opacity 0.6s ease-out ${delay}s, transform 0.6s ease-out ${delay}s`
  };

  return (
    <div
      ref={ref}
      className="tipss"
      style={itemStyle}
    >
      <Tags tags={tags} />
      {codepenUrl && <CodepenButton url={codepenUrl} disabled={index === 9} />}
      <div className={`tip-item tip${index}`}>
        {children}
      </div>
    </div>
  );
});

// Clock component extracted for better organization
const Clock = memo(({ timeString, glowClass, timerRunning, handleButtonClick }) => (
  <div className={`clock-container ${glowClass}`}>
    <div className="clock-container2">
      <div className={`clock-outline ${glowClass}`}>
        <div className="clock-outline2"></div>
        <div className="actual-clock">
          <div className="clock-text">{timeString}</div>
          <button
            className="clock-button"
            onClick={handleButtonClick}
            disabled={timerRunning}
          ></button>
        </div>
      </div>
    </div>
  </div>
));

// Main component
const CSStips = () => {
  const [timeString, setTimeString] = useState("88:88:88");
  const [glowClass, setGlowClass] = useState("");
  const [timerRunning, setTimerRunning] = useState(false);
  const { isRetroMode, toggleRetroMode } = useTheme();
  
  const titleRef = useRef(null);
  const containerRef = useRef(null);
  const isTitleInView = useInView(titleRef, { once: true, amount: 0.1 });
  const isContainerInView = useInView(containerRef, { once: true, amount: 0.1 });

  // Optimize update time with useCallback
  const updateTime = useCallback(() => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    let seconds = now.getSeconds();

    hours = hours % 12;
    hours = hours ? hours : 12;

    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;

    return `${hours}:${minutes}:${seconds}`;
  }, []);

  useEffect(() => {
    // Only run interval when needed
    if (!timerRunning) {
      const intervalId = setInterval(() => {
        setTimeString("88:88:88");
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timerRunning]);

  const handleButtonClick = useCallback(() => {
    if (timerRunning) return;

    setTimerRunning(true);
    setGlowClass("glow");
    setTimeString(updateTime());

    setTimeout(() => {
      setGlowClass("");
      setTimeString("88:88:88");
      setTimerRunning(false);
    }, 2000);
  }, [timerRunning, updateTime]);

  // Define all tips data in one place
  const tipsData = [
    {
      index: 1,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/GRVLzmM",
      content: (
        <div className="Mountains Card">
          <p className="christmasText">Merry Christmas</p>

          {/* Mountains */}
          <div className="Mountain MountainLeft1"></div>
          <div className="Mountain MountainLeft2"></div>
          <div className="Mountain MountainLeft3"></div>
          <div className="Mountain MountainMiddle"></div>
          <div className="Mountain MountainRight1"></div>
          <div className="Mountain MountainRight2"></div>
          <div className="Mountain MountainRight3"></div>

          {/* Using memoized Flake components */}
          <Flake />
          <Flake />
          <Flake />
          <Flake />
          <Flake />
        </div>
      )
    },
    {
      index: 2,
      tags: ["html", "css", "js"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/dyEXxdj",
      content: (
        <Clock 
          timeString={timeString} 
          glowClass={glowClass} 
          timerRunning={timerRunning} 
          handleButtonClick={handleButtonClick} 
        />
      )
    },
    {
      index: 3,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/MWRoYxv",
      content: (
        <div className="loading-dotys">
          <div className="doty-1"></div>
          <div className="doty-2"></div>
          <div className="doty-3"></div>
          <div className="doty-4"></div>
        </div>
      )
    },
    {
      index: 4,
      tags: ["html", "css", "js"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/bGJBebb",
      content: (
        <div className="retro-mode-toggle">
          <input
            type="checkbox"
            id="retro-mode-color-switch"
            checked={isRetroMode}
            onChange={toggleRetroMode}
          />
          <label
            className="color-switch"
            htmlFor="retro-mode-color-switch"
          >
            <div className="retro">
              <div className="ball"></div>
              <p className="on-text">
                <em>ON</em>
              </p>
              <p className="off-text">OFF</p>
            </div>
          </label>
        </div>
      )
    },
    {
      index: 5,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/mdZewpr",
      content: (
        <div className="loading"></div>
      )
    },
    {
      index: 6,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/xbKGrdy",
      content: (
        <div className="fun-itemys">
          <div className="itemy itemy1">
            <p className="itemytitle">FUN</p>
          </div>
        </div>
      )
    },
    {
      index: 7,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/emONRBy",
      content: (
        <div className="phone">
          <div className="iphonebtn"></div>
          <div className="iphonespeaker"></div>
          <div className="iphonecamera"></div>
        </div>
      )
    },
    {
      index: 8,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/YPKXQWb",
      content: (
        <div className="browser-tab">
          <div className="browserclose">
            <div className="line1"></div>
            <div className="line2"></div>
          </div>
          <div className="browsertask">
            <div className="square"></div>
          </div>
          <div className="browserminimize">
            <div className="dashy"></div>
          </div>
          <div className="browser-inside">404</div>
        </div>
      )
    },
    {
      index: 10,
      tags: ["html", "css"],
      codepenUrl: "https://codepen.io/Mooncaque/pen/mydQbEK",
      content: (
        <div class="stack-card">
        <div class="mongo2">
           <div class="leaf2"></div>
        </div>
  
        <div class="express2">
          <div class="square2"><p>E</p></div>
        </div>
      
        <div class="react-container2">
            <div class="oval-12"><div class="react-dot2"></div></div>
            <div class="oval-22"></div>
            <div class="oval-32"></div>
        </div>
  
  
        <div class="node-container2">
            <div class="polygon-container2">
                <div class="polygon2"></div>
            </div>
         </div>
  
      </div>
      )
    },
    {
      index: 9,
      tags: [],
      content: (
        <p>More Coming Soon!</p>
      )
    }
  ];

  return (
    <section id="tips-section" className="tips-main">
      <Helmet>
        <title>Nisal Herath - Fun w/CSS </title>
        <meta
          name="description"
          content="Web site created by Nisal Herath, showcasing projects and portfolio."
        />
        <meta
          name="keywords"
          content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka"
        />
        <meta name="author" content="Nisal Herath" />
        <meta
          property="og:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          property="og:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          property="og:image"
          content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364"
        />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          name="twitter:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <LazyMotion features={domAnimation}>
        <div className="titlediv">
          <div
            ref={titleRef}
            className="css-title"
            style={{
              opacity: isTitleInView ? 1 : 0,
              transform: isTitleInView ? 'translateY(0)' : 'translateY(-50px)',
              transition: 'opacity 0.6s ease-out, transform 0.6s ease-out'
            }}
          >
            <Link
              to="/"
              className="backhome3"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="arrowhead2">
                <div className="arrowline12"></div>
                <div className="arrowline22"></div>
                <div className="arrowmainline2"></div>
              </div>
              Back Home
            </Link>

            <h1>Fun w/CSS</h1>
          </div>

          {/* Using memoized Flake components */}
          <Flake />
          <Flake />
          <Flake />
          <Flake />
          <Flake />
        </div>
        
        <div
          ref={containerRef}
          className="tips-component"
          style={{
            opacity: isContainerInView ? 1 : 0,
            transform: isContainerInView ? 'translateY(0)' : 'translateY(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
          }}
        >
          <div className="tips-items">
            {tipsData.map((tip) => (
              <TipItem 
                key={tip.index}
                index={tip.index}
                tags={tip.tags}
                codepenUrl={tip.codepenUrl}
              >
                {tip.content}
              </TipItem>
            ))}
          </div>
        </div>
      </LazyMotion>
      <Footer />
    </section>
  );
};

export default CSStips;