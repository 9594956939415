import * as React from "react";
const PenSwing = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={389.66}
    height={370.94}
    viewBox="0 0 389.66 370.94"
    className="swivel-pen"
    {...props}
  >
    <title>{"pen tool"}</title>
    <g id="line-stable">
      <g>
        <g>
          <polygon
            points="147.65 188.38 158.92 188.38 158.92 201 146.29 201 146.29 188.38 147.65 188.38"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="148.24 14.13 146.29 14.13 146.29 1.5 158.92 1.5 158.92 14.13 148.24 14.13"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <g>
          <path
            d="M203.14,78.58c-14.42,22.23-56.46,99.57-.59,174.25"
            transform="translate(-54.9 -64.45)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <polygon
            points="158.92 201 146.29 201 146.29 188.38 147.65 188.38 158.92 188.38 158.92 201"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <polygon
            points="158.92 14.13 148.24 14.13 146.29 14.13 146.29 1.5 158.92 1.5 158.92 14.13"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
        </g>
      </g>
    </g>
    <g id="swivel-pen">
      <g>
        <g>
          <path
            d="M383,413a2.59,2.59,0,0,1,0,3.63l-18,18a2.59,2.59,0,0,1-3.63,0l-13.44-13.44a2.57,2.57,0,0,1,0-3.63l18-18a2.57,2.57,0,0,1,3.63,0Z"
            transform="translate(-54.9 -64.45)"
          />
          <path
            d="M278.73,330.33a2.1,2.1,0,0,1,2.64-.27,81.81,81.81,0,0,0,14.06,6.71A113.88,113.88,0,0,0,332.64,344a2.13,2.13,0,0,1,1.45.62c.64.64,15.94,16.14,29.29,46.5a2.12,2.12,0,0,1-.44,2.34l-21.08,21.07a2.1,2.1,0,0,1-2.34.45c-30.36-13.36-45.85-28.65-46.5-29.29a2.2,2.2,0,0,1-.62-1.45A113.89,113.89,0,0,0,285.16,347a81.42,81.42,0,0,0-6.71-14,2.09,2.09,0,0,1,.27-2.62v0Zm4.79,4.82.06.07a378.21,378.21,0,0,0,28.48,31.48c10.42,10.3,17.84,17.17,22.16,20.73a5.28,5.28,0,1,0,1.6-1.6c-3.56-4.33-10.43-11.74-20.73-22.17a377.51,377.51,0,0,0-31.64-28.6Z"
            transform="translate(-54.9 -64.45)"
          />
          <path
            d="M111.61,163.24a8.4,8.4,0,1,1,2.46-5.94A8.37,8.37,0,0,1,111.61,163.24Z"
            transform="translate(-54.9 -64.45)"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <g>
          <polyline
            points="56.71 98.79 223.82 265.91 228.62 270.7 228.68 270.77 254.18 296.27"
            style={{
              fill: "none",
              stroke: "#94ff85",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <path
            d="M114.07,157.3a8.4,8.4,0,1,1-8.4-8.4A8.37,8.37,0,0,1,114.07,157.3Z"
            transform="translate(-54.9 -64.45)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <path
            d="M88.42,172.64s-19,24.25-20.46,56.84"
            transform="translate(-54.9 -64.45)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="1.5 160.66 14.38 184.01 24.62 160.66"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <path
            d="M417.26,368.47s16.05-26.27,13.81-58.81"
            transform="translate(-54.9 -64.45)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="388.16 248.22 372.68 226.51 365.19 250.87"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default PenSwing;
