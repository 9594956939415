import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import LenisSetup from './Lenis/LenisSetup';
import { ThemeProvider } from "./Theme/Theme";
import ScrollToTop from './ScrollToTop/ScrollToTop';
import HomePage from './Components/HomePage/HomePage';
import NotFound from './Components/NotFound/Notfound';
import CSStips from './Components/CSStips/CSStips';
import Nerdcake from './Components/Nerdcake/Nerdcake';
import Advice from './Components/Advice/Advice';
import Astro from './Components/Astro/Astro';
import QrGen from './Components/QrGen/QrGen';
import WallGallery from './Components/WallGallery/WallGallery';
import KittyDetect from './Components/KittyDetect/KittyDetect';
import Blog from './Components/Blog/Blog';
import Blog1 from './Components/Blog/BlogPages/Blog1/Blog1';
import Blog2 from './Components/Blog/BlogPages/Blog2/Blog2';
import Blog3 from './Components/Blog/BlogPages/Blog3/Blog3';
import WordGen from './Components/WordGen/WordGen';
import Customer from './Components/Customer/Customer';
import './App.css';

const validSections = ['about', 'experience', 'skills', 'projects', 'fun'];

function ScrollPositionManager() {
  const location = useLocation();

  useEffect(() => {
    // Only preserve scroll position for homepage
    if (location.pathname === '/') {
      const scrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions') || '{}');
      const savedPosition = scrollPositions[location.pathname];

      // Restore scroll position if exists
      if (savedPosition !== undefined) {
        window.scrollTo(0, savedPosition);
      }

      // Save scroll position when leaving
      const handleScroll = () => {
        const currentScrollPositions = JSON.parse(sessionStorage.getItem('scrollPositions') || '{}');
        currentScrollPositions[location.pathname] = window.scrollY;
        sessionStorage.setItem('scrollPositions', JSON.stringify(currentScrollPositions));
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      // Scroll to top for all other routes
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  // Section scrolling
  useEffect(() => {
    const sectionId = location.hash.substring(1).toLowerCase();

    if (sectionId && validSections.includes(sectionId)) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else if (sectionId) {
      window.location.href = '/404';
    }
  }, [location]);

  return null;
}

function App() {
  return (
    <Router>
      <ThemeProvider>
        <LenisSetup>
          <ScrollPositionManager />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/css" element={<CSStips />} />
            <Route path="/nerdcake" element={<Nerdcake />} />
            <Route path="/advice" element={<Advice />} />
            <Route path="/astro" element={<Astro />} />
            <Route path="/qr-generator" element={<QrGen />} />
            <Route path="/wallpaper-gallery" element={<WallGallery />} />
            <Route path="/kitty-detect" element={<KittyDetect />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/1" element={<Blog1 />} />
            <Route path="/blog/2" element={<Blog2 />} />
            <Route path="/blog/3" element={<Blog3 />} />
            <Route path="/word-generation" element={<WordGen />} />
            <Route path="/client=UGFzaW5kdUxha3J1d2Fu" element={<Customer />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
          <ScrollToTop /> 
        </LenisSetup>
      </ThemeProvider>
    </Router>
  );
}

export default App;