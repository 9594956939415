import React, { useState,useEffect } from 'react';
import { motion} from 'framer-motion';
import "./KittyDetect.css";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import 'react-tooltip/dist/react-tooltip.css';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import githubLogo from '../Assets/git.svg';

const fadeVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const slideXVariant = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const slideInFromRight = {
  hidden: { x: "50%", opacity: 0 },
  visible: { x: 0, opacity: 1 },
};

const slideYVariant = {
  hidden: { y: -100, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};

const barHeightVariant = (height) => ({
  hidden: { height: "0%" },
  visible: { height },
});

const KittyDetect = () => {
  const [percentage, setPercentage] = useState(0);
  const [hovering, setHovering] = useState(false);


  const handleMouseEnter = () => {
    setHovering(true);
    setPercentage(82);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  useEffect(() => {
    if (!hovering) {
      setPercentage(0);
    }
  }, [hovering]);

  const handleClick = () => {
    if (percentage === 0) {
      setPercentage(82);
    } else {
      setPercentage(0);
    }
  };




  return (
    <section id="nerdcake" className="nerdcake-main">

        <Helmet>
        <title>Nisal Herath - KittyDetect </title>
        <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
        <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
        <meta name="author" content="Nisal Herath" />
        <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
        <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="nerdcake-component">
      <motion.div
          className="nerdcake"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.1 }}
          variants={fadeVariant}
          transition={{ duration: 0.5 }}
        >
          
          <div className="maintwocardsfeline">
          <Link to="/" className="backhome3" style={{ textDecoration: "none", color: "inherit" }}>
  <div className="arrowhead2">
    <div className="arrowline12"></div>
    <div className="arrowline22"></div>
    <div className="arrowmainline2"></div>
  </div>
  Back Home
</Link>


  <div className="felinecard">
    <motion.div
      className="felinepic"
      initial="hidden"
      animate="visible"
      variants={slideXVariant}
      transition={{ duration: 0.8 }}
    >
      <p>🐱</p>
    </motion.div>

    <motion.h2
      className="cakename"
      initial="hidden"
      animate="visible"
      variants={slideYVariant}
      transition={{ duration: 0.8 }}
    >
      Kitty-Detect
    </motion.h2>

    <motion.p
      className="cake-desc"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={slideXVariant}
      transition={{ duration: 0.8, delay: 0.4 }}
    >
This project uses deep learning to classify cat images into two categories: My Cat or Other Cats using a fine-tuned ResNet-50 model. It also integrates YOLO for input validation, ensuring only valid cat images are processed. With an interactive UI, users can upload images and instantly see results, making it a fun and accurate way to identify their feline friends!
    </motion.p>
  </div>

  <div className="commandcardfeline">
    <p className="commandtitle">Key Features</p>
    <div className="commandlinesfeline">
      <motion.p
        className="felinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0 }}
      >
        ✅ Smart Image Upload
      </motion.p>

      <motion.p
        className="felinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0 }}
      >
        ✅ AI-Powered Filtering
      </motion.p>

      <motion.p
        className="felinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0 }}
      >
        ✅ Deep Learning Classification
      </motion.p>


      <motion.p
        className="felinetext"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        variants={slideXVariant}
        transition={{ duration: 0.8, delay: 0 }}
      >
        ✅ Instant Results
      </motion.p>
      
    </div>
  </div>
</div>


          <div className="felinegraphcard">
            <div className="graphfeline">
              <motion.div
                className="felinebar1"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("55%")}
                transition={{ duration: 1 }}
              >

                <p>Overall</p>
                <p>82%</p>
              </motion.div>

              <motion.div
                className="felinebar2"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("70%")}
                transition={{ duration: 1 }}
              >

                <p>YOLO</p>
                <p>98%</p>
              </motion.div>

              <motion.div
                className="felinebar3"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.8 }}
                variants={barHeightVariant("60%")}
                transition={{ duration: 1 }}
              >
                <p>ResNet50</p>
                <p>90%</p>
              </motion.div>


              <p className="unness">Accuracy Graph</p>
              <div className="graphline"></div>
            </div>

            <div className="feline-graph-desc">
              <motion.p
                className="felinegraphtext"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 0.8, delay: 0.4 }}>
                      The Cat Image Classifier leverages a fine-tuned ResNet-50 model, enhanced by YOLO-based filtering, to accurately classify images as "My Cat" vs. "Other Cats". With a focus on GPU acceleration and advanced data augmentation techniques, the system delivers precise and reliable results. It's designed for both speed and accuracy, ensuring that your feline friend is identified correctly every time.
      </motion.p>

      <a
  href="https://github.com/nisalherath/Kitty-Detect"
  target="_blank"
  rel="noopener noreferrer"
  className="discordbtnfeline"
  aria-label="View Kitty-Detect project on GitHub"
  style={{ textDecoration: 'none' }}
>
  <img src={githubLogo} className="gitty" alt="GitHub logo" />
  Find the Code on GitHub
</a>



            </div>

      <motion.div className="feline24"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={slideXVariant}
      transition={{ duration: 0.8, delay: 0.4 }}
    >
        <CircularProgressbar
          value={percentage}
          styles={buildStyles({
            textColor: "black",
            pathColor: "black",
            trailColor: "#d6d6d6",
            strokeLinecap: "round",
            transition: "stroke-dashoffset 0.6s ease 0s", // Smooth transition
          })}
        />
        <h1 className="runningfeline">82% + Accuracy</h1>
      </motion.div>


      <motion.div className="boundryboximg"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.1 }}
      variants={slideInFromRight}
      transition={{ duration: 0.8, delay: 0.4 }}
    >

       
      </motion.div>





          </div>





        </motion.div>
      </div>
    </section>
  );
};

export default KittyDetect;


