import React, { useRef, useEffect, useState } from "react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./Skills.css";
import { Autoplay } from "swiper/modules";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import reactImg from "../Assets/reactlogo.svg";
import javascriptImg from "../Assets/js.svg";
import pythonImg from "../Assets/py.svg";
import nodeImg from "../Assets/node.svg";
import firebaseImg from "../Assets/firebase.svg";
import cssImg from "../Assets/css.svg";
import htmlImg from "../Assets/html.svg";
import afterEffectsImg from "../Assets/ae.svg";
import mongodbImg from "../Assets/mongo.svg";
import photoshopImg from "../Assets/ps.svg";
import unsplashImg from "../Assets/unsplash.svg";
import illustratorImg from "../Assets/ai.svg";

const skillImages = [
  { src: reactImg, alt: "React", className: "react-skill" },
  { src: javascriptImg, alt: "JavaScript", className: "javascript-skill" },
  { src: pythonImg, alt: "Python", className: "python-skill" },
  { src: nodeImg, alt: "Node.js", className: "node-skill" },
  { src: firebaseImg, alt: "Firebase", className: "firebase-skill" },
  { src: cssImg, alt: "CSS", className: "css-skill" },
  { src: htmlImg, alt: "HTML", className: "html-skill" },
  { src: afterEffectsImg, alt: "After Effects", className: "ae-skill" },
  { src: mongodbImg, alt: "MongoDB", className: "mongodb-skill" },
  { src: photoshopImg, alt: "Photoshop", className: "photoshop-skill" },
  { src: unsplashImg, alt: "Unsplash", className: "unsplash-skill" },
  { src: illustratorImg, alt: "Illustrator", className: "illustrator-skill" },
];

const Skills = () => {
  const swiperRef = useRef(null);
  const touchStart = useRef(null);
  const [autoScroll, setAutoScroll] = useState(true);

  const handleScroll = (event) => {
    if (!swiperRef.current) return;

    setAutoScroll(false);

    const delta = event.deltaY > 0 ? 1 : -1;
    swiperRef.current.swiper.slideTo(
      swiperRef.current.swiper.activeIndex + delta,
    );
  };

  const handleTouchStart = (event) => {
    touchStart.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event) => {
    if (!swiperRef.current || !touchStart.current) return;

    const currentTouch = event.touches[0].clientY;
    const delta = touchStart.current - currentTouch;

    if (Math.abs(delta) > 10) {
      if (event.cancelable) {
        event.preventDefault();
      }

      const direction = delta > 0 ? -1 : 1;
      swiperRef.current.swiper.slideTo(
        swiperRef.current.swiper.activeIndex + direction,
      );
      touchStart.current = currentTouch;
    }
  };

  useEffect(() => {
    const skillsElement = document.getElementById("skills");

    const handleSectionScroll = (event) => {
      handleScroll(event);
    };

    const handleSectionTouchStart = (event) => {
      handleTouchStart(event);
    };

    const handleSectionTouchMove = (event) => {
      handleTouchMove(event);
    };

    skillsElement.addEventListener("wheel", handleSectionScroll, {
      passive: true,
    });
    skillsElement.addEventListener("touchstart", handleSectionTouchStart, {
      passive: true,
    });
    skillsElement.addEventListener("touchmove", handleSectionTouchMove, {
      passive: false,
    });
    return () => {
      skillsElement.removeEventListener("wheel", handleSectionScroll);
      skillsElement.removeEventListener("touchstart", handleSectionTouchStart);
      skillsElement.removeEventListener("touchmove", handleSectionTouchMove);
    };
  }, [autoScroll]);

  const fadeInOptimized = {
    initial: { opacity: 0, y: 50 },
    whileInView: { opacity: 1, y: 0 },
    viewport: { once: true, amount: 0.1 },
    transition: { duration: 0.8, ease: "easeOut" },
  };

  return (
    <div className="skills-main">
      <LazyMotion features={domAnimation}>
        <m.section id="skills" className="skills-section" {...fadeInOptimized}>
          <h1 className="skillsheader">Skills</h1>
          <div className="Skillstext">
            <h1 className="skillstextheader">Skills in Me</h1>
            <div className="Skillstext-content">
              I am skilled in React for building dynamic web applications,
              JavaScript for both frontend and backend development, and Python
              for creating automation bots. I work with Node.js for backend
              solutions and Firebase for real-time apps. My expertise also
              includes HTML, CSS, and MongoDB for web design and database
              management, as well as After Effects, Photoshop, and Illustrator
              for media creation. I’m always refining my skills to deliver
              innovative and efficient solutions.
            </div>

            <div className="my-stack">
              <h2 className="stack-title">My Stack</h2>
              <div className="stack-actual">
                <div className="mongo">
                  <div className="leaf"></div>
                </div>

                <div className="express">
                  <div className="squaree">
                    <p>E</p>
                  </div>
                </div>

                <div className="react-container">
                  <div className="oval-1">
                    <div className="react-dot"></div>
                  </div>
                  <div className="oval-2"></div>
                  <div className="oval-3"></div>
                </div>

                <div className="node-container">
                  <div className="polygon-container">
                    <div className="polygon"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Swiper
            ref={swiperRef}
            spaceBetween={20}
            slidesPerView={5}
            loop={true}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {skillImages.map((skill, index) => (
              <SwiperSlide
                key={index}
                onClick={() => {
                  if (swiperRef.current) {
                    const swiper = swiperRef.current.swiper;
                    const totalSlides = swiper.slides.length;
                    const middleIndex = Math.floor(
                      swiper.params.slidesPerView / 2,
                    );
                    const targetIndex = index - middleIndex;
                    const adjustedIndex =
                      (targetIndex + totalSlides) % totalSlides;
                    swiper.slideToLoop(adjustedIndex);
                  }
                }}
              >
                <div className={`skill-card ${skill.className}`}>
                  <img
                    src={skill.src}
                    alt={skill.alt}
                    className={`skill-img ${skill.className}`}
                    data-tooltip-id="skill-tooltip"
                    data-tooltip-content={skill.alt}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <ReactTooltip
            id="skill-tooltip"
            effect="solid"
            place="top"
            multiline={true}
            className="custom-tooltip"
            clickable={true}
            delayShow={100}
            delayHide={500}
            positionStrategy="fixed"
            resizeObserver={true}
            closeOnScroll={true}
            arrowColor="rgba(31, 22, 83, 0.7)"
          />
        </m.section>
      </LazyMotion>
    </div>
  );
};

export default Skills;
