import React, { useRef } from "react";
import { LazyMotion, domAnimation, useInView } from "framer-motion";
import "./Customer.css";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";

import github from "../Assets/github.svg";

// Project Card component with Fun-style animation improvements
const ProjectCard = ({ project, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });
  
  // Stagger the animation delay based on item index
  const delay = index * 0.1;
  
  return (
    <div 
      ref={ref}
      className="project-card card"
      style={{
        transform: isInView ? "translateY(0)" : "translateY(20px)",
        opacity: isInView ? 1 : 0,
        transition: `opacity 0.6s ease-out ${delay}s, transform 0.6s ease-out ${delay}s`
      }}
    >
      <div className="project">
        <div className="projectstext">
          <h1 className="projectheader">{project.title}</h1>
          <div className="project-tags">
            {project.tags.map((tag, index) => (
              <span key={index} className={`label ${tag.className}`}>{tag.text}</span>
            ))}
          </div>
          {project.description}
        </div>
        <div className="button-container">
          {project.links.map((link, index) => (
            link.isLink ? (
              <Link
                key={index}
                to={link.url}
                className="githubbtn githubbtn1"
                aria-label={link.ariaLabel}
              >
                <img
                  src={link.icon}
                  className="githubimg"
                  alt={link.alt}
                  onError={(e) => (e.target.style.display = "none")}
                />
                {link.text}
              </Link>
            ) : (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label={link.ariaLabel}
                style={{ textDecoration: "none" }}
              >
                <img src={link.icon} className="githubimg" alt={link.alt} />
                {link.text}
              </a>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

const Customer = () => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, amount: 0.1 });

  // Project data
  const projects = [
    {
      title: "Safe Mail Scan (Python, GUI Only)",
      tags: [
        { text: "Python", className: "fun" },
        { text: "UI Only", className: "engaging" },
        { text: "No Backend", className: "compatible" },
        { text: "Inuka's Friend", className: "interactive" },
        { text: "Pending 🕙", className: "interactive" }
      ],
      description: "Client needs an Interactive UI design for a, Dashboard Centric Desktop App. ",
      links: [
        {
          isLink: false,
          url: "https://github.com/nisalherath/SafeMailScan",
          icon: github,
          alt: "GitHub Logo",
          text: "Source Code (Unf)",
          ariaLabel: "View Source Code on GitHub"
        }
      ]
    }
  ];

  return (
    <div className="projects-main">
      <LazyMotion features={domAnimation}>
        <section 
          id="projects" 
          ref={containerRef}
          className="projects-section"
          style={{
            opacity: isInView ? 1 : 0,
            transform: isInView ? 'translateY(0)' : 'translateY(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
          }}
        >
          <h1 className="customerHeader">Client:Pasindu</h1>

          <div className="project-flex">
            {projects.map((project, index) => (
              <ProjectCard key={index} project={project} index={index} />
            ))}
          </div>
        </section>
      </LazyMotion>
    </div>
  );
};

export default Customer;