import React, { useRef } from "react";
import Footer from "../Footer/Footer";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { LazyMotion, domAnimation, useInView } from "framer-motion";
import "./Blog.css";

// Optimized BlogItem component to reduce re-renders
const BlogItem = ({ item, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });
  const { path, title, disabled } = item;
  
  // Stagger the animation delay based on item index
  const delay = index * 0.1;
  
  const itemStyle = {
    transform: isInView ? "translateY(0)" : "translateY(20px)",
    opacity: isInView ? (disabled ? 0.5 : 1) : 0,
    transition: `opacity 0.6s ease-out ${delay}s, transform 0.6s ease-out ${delay}s`,
    pointerEvents: disabled ? "none" : "auto"
  };

  return (
    <Link
      ref={ref}
      to={disabled ? "#" : path}
      className="blogs-item-container"
      style={itemStyle}
    >
      <div
        className="blogs"
        name={title}
        title={title}
      >
        <div className="blogs-item">
          <p>{title}</p>
        </div>
      </div>
    </Link>
  );
};

const BlogItems = () => {
  const blogPosts = [
    { path: "/blog/1", title: "Pro Art", disabled: false },
    { path: "/blog/2", title: "Sinhala Word API", disabled: false },
    { path: "/blog/3", title: "HTML inside an SVG", disabled: false },
    { path: "/blog/4", title: "More Coming Soon", disabled: true },
  ];

  return (
    <div className="blogs-items">
      {blogPosts.map((item, index) => (
        <BlogItem key={item.title} item={item} index={index} />
      ))}
    </div>
  );
};

const Blog = () => {
  const containerRef = useRef(null);
  const titleRef = useRef(null);
  const isContainerInView = useInView(containerRef, { once: true, amount: 0.1 });
  const isTitleInView = useInView(titleRef, { once: true, amount: 0.1 });

  return (
    <section id="blog-section" className="blogs-main">
      <Helmet>
        <title>Nisal Herath - Blog</title>
        <meta
          name="description"
          content="Web site created by Nisal Herath, showcasing projects and portfolio."
        />
        <meta
          name="keywords"
          content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka"
        />
        <meta name="author" content="Nisal Herath" />
        <meta
          property="og:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          property="og:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta
          property="og:image"
          content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364"
        />
        <meta property="og:url" content="https://nisal.lk" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Nisal Herath - Developer & Automation Specialist"
        />
        <meta
          name="twitter:description"
          content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation."
        />
        <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <LazyMotion features={domAnimation}>
        <div className="blogtitlediv">
          <div 
            ref={titleRef} 
            className="blog-title"
            style={{
              opacity: isTitleInView ? 1 : 0,
              transform: isTitleInView ? 'translateY(0)' : 'translateY(-50px)',
              transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
            }}
          >
            <h1>My Blog</h1>

            <div className="blog-disclaimer">
              <p>
                All the blogs displayed in this Page are originally sourced from{" "}
                <a
                  href="https://x.com/ArtNisal"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="blog-link"
                >
                  Nisal Herath
                </a>
                , used under their license for free and commercial purposes with
                proper credit.
                <br />
                For concerns, feel free to contact me.
              </p>
            </div>

            <Link
              to="/"
              className="backhome3"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <div className="arrowhead2">
                <div className="arrowline12"></div>
                <div className="arrowline22"></div>
                <div className="arrowmainline2"></div>
              </div>
              Back Home
            </Link>
          </div>
        </div>

        <div 
          ref={containerRef}
          style={{
            opacity: isContainerInView ? 1 : 0,
            transform: isContainerInView ? 'translateY(0)' : 'translateY(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
          }}
        >
          <BlogItems />
        </div>
      </LazyMotion>

      <Footer />
    </section>
  );
};

export default Blog;