import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { LazyMotion, domAnimation, useInView } from "framer-motion";
import { Helmet } from "react-helmet";
import "./Fun.css";

// Optimized Item component to reduce re-renders
const FunItem = ({ item, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });
  const { href, label, emoji, external } = item;
  
  // Stagger the animation delay based on item index
  const delay = index * 0.1;
  
  const itemStyle = {
    transform: isInView ? "translateY(0)" : "translateY(20px)",
    opacity: isInView ? 1 : 0,
    transition: `opacity 0.6s ease-out ${delay}s, transform 0.6s ease-out ${delay}s`
  };

  if (external) {
    return (
      <a
        ref={ref}
        className={`item item${label.replace(/\s+/g, "").toLowerCase()}`}
        href={href}
        aria-label={label}
        target="_blank"
        rel="noopener noreferrer"
        style={{ 
          textDecoration: "none", 
          color: "inherit",
          ...itemStyle
        }}
      >
        <p className="itemtitle">{emoji}</p>
        <p className="itemtitle">{label}</p>
      </a>
    );
  }
  
  return (
    <Link
      ref={ref}
      className={`item item${label.replace(/\s+/g, "").toLowerCase()}`}
      to={href}
      aria-label={label}
      style={{ 
        textDecoration: "none", 
        color: "inherit",
        ...itemStyle
      }}
    >
      <p className="itemtitle">{emoji}</p>
      <p className="itemtitle">{label}</p>
    </Link>
  );
};

const FunItems = () => {
  const items = [
    { href: "/blog", label: "My Blog", emoji: "🏝️", external: false },
    { href: "/css", label: "CSS Tips", emoji: "💡", external: false },
    { href: "/word-generation", label: "Sinhala Word Gen", emoji: "🅰️✖️3️⃣", external: false },
    {
      href: "https://game.nisal.lk/",
      label: "Fun Game",
      emoji: "🎮",
      external: true,
    },
    { href: "/advice", label: "Random Advice", emoji: "🧙🏻‍♂️", external: false },
    { href: "/astro", label: "Moon Phase", emoji: "🌒🌕🌘", external: false },
    { href: "/qr-generator", label: "QR Generation", emoji: "⚙️", external: false },
    { href: "/wallpaper-gallery", label: "Doom-Scroll Images", emoji: "🌅", external: false },
  ];

  return (
    <div className="fun-items">
      {items.map((item, index) => (
        <FunItem key={item.label} item={item} index={index} />
      ))}
    </div>
  );
};

const Fun = () => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, amount: 0.1 });

  return (
    <section id="fun" className="fun-main">
      <Helmet>
        <meta
          name="description"
          content="Discover exciting features like CSS tips, the Nerdcake bot, a fun game, random advice, and today's moon phase. Explore and enjoy interactive content!"
        />
        <meta
          name="keywords"
          content="CSS Tips, Nerdcake, Fun Game, Random Advice, Moon Phase, Interactive Content, Nisal Herath"
        />
        <meta property="og:title" content="Explore Fun Features - Nisal Herath" />
        <meta
          property="og:description"
          content="Discover exciting features like CSS tips, the Nerdcake bot, a fun game, random advice, and today's moon phase. Explore and enjoy interactive content!"
        />
        <meta property="og:url" content="https://nisal.lk/fun" />
        <meta
          property="og:image"
          content="https://nisal.lk/assets/fun-preview.jpg"
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Explore Fun Features - Nisal Herath" />
        <meta
          name="twitter:description"
          content="Interactive features like CSS tips, Nerdcake, fun games, random advice, and more."
        />
        <meta
          name="twitter:image"
          content="https://nisal.lk/assets/fun-preview.jpg"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <LazyMotion features={domAnimation}>
        <div 
          ref={containerRef}
          className="fun-component"
          style={{
            opacity: isInView ? 1 : 0,
            transform: isInView ? 'translateY(0)' : 'translateY(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
          }}
        >
          <h1 className="projectsheader2">What I do 4 FUN</h1>
          <FunItems />
        </div>
      </LazyMotion>
    </section>
  );
};

export default Fun;