import { createContext, useContext, useEffect, useState } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const getInitialMode = () => {
    const storedMode = localStorage.getItem("retroModeEnabled");
    
    const isDarkModePreferred = window.matchMedia("(prefers-color-scheme: dark)").matches;
    const isRetro = storedMode !== null ? storedMode === "true" : !isDarkModePreferred;

    //prevent flash
    if (isRetro) {
      document.documentElement.classList.add("retro-mode-enable");
    } else {
      document.documentElement.classList.remove("retro-mode-enable");
    }

    return isRetro;
  };

  const [isRetroMode, setIsRetroMode] = useState(getInitialMode());

  useEffect(() => {
    if (isRetroMode) {
      document.documentElement.classList.add("retro-mode-enable");
      localStorage.setItem("retroModeEnabled", "true");
    } else {
      document.documentElement.classList.remove("retro-mode-enable");
      localStorage.setItem("retroModeEnabled", "false");
    }
  }, [isRetroMode]);

  const toggleRetroMode = () => setIsRetroMode((prev) => !prev);

  return (
    <ThemeContext.Provider value={{ isRetroMode, toggleRetroMode }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
