import React, { useState } from "react";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import "./WordGen.css";

function WordGen() {
  const [loading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isCustomGenerating, setIsCustomGenerating] = useState(false);
  const [word, setWord] = useState("");

  // Comprehensive Singlish to Sinhala conversion function
// Comprehensive Singlish to Sinhala conversion function
const convertSinglishToSinhala = (text) => {
  if (!text) return "";
  
  // Convert characters to an array to process them
  let chars = text.split('');
  let result = "";
  let i = 0;
  
  while (i < chars.length) {
    // Handle quadruple character combinations first
    if (i + 3 < chars.length) {
      const fourChars = chars[i] + chars[i + 1] + chars[i + 2] + chars[i + 3];
      
      // Quadruple character combinations
      if (fourChars === 'thth') {
        result += 'ත්ථ්';
        i += 4;
        continue;
      }
    }
    
    // Handle triple character combinations
    if (i + 2 < chars.length) {
      const threeChars = chars[i] + chars[i + 1] + chars[i + 2];
      
      // Triple character combinations
      if (threeChars === 'aae') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ෑ'; // Add aae sound
        } else {
          result += 'ඈ';
        }
        i += 3;
        continue;
      } else if (threeChars === 'ndh') {
        result += 'න්ධ්';
        i += 3;
        continue;
      } else if (threeChars === 'ndr') {
        result += 'න්ද්‍ර්';
        i += 3;
        continue;
      } else if (threeChars === 'ndu') {
        result += 'න්දු';
        i += 3;
        continue;
      } else if (threeChars === 'nda') {
        result += 'න්ද';
        i += 3;
        continue;
      } else if (threeChars === 'ksh') {
        result += 'ක්ෂ්';
        i += 3;
        continue;
      } else if (threeChars === 'thr') {
        result += 'ත්‍ර්';
        i += 3;
        continue;
      } else if (threeChars === 'shr') {
        result += 'ශ්‍ර්';
        i += 3;
        continue;
      } else if (threeChars === 'chr') {
        result += 'ච්‍ර්';
        i += 3;
        continue;
      }
    }
    
    // Handle special consonants (double characters)
    if (i + 1 < chars.length) {
      const twoChars = chars[i] + chars[i + 1];
      
      // Special consonants
      if (twoChars === 'th') {
        result += 'ත්';
        i += 2;
        continue;
      } else if (twoChars === 'dh') {
        result += 'ද්';
        i += 2;
        continue;
      } else if (twoChars === 'ch') {
        result += 'ච්';
        i += 2;
        continue;
      } else if (twoChars === 'Ch') {
        result += 'ඡ්';
        i += 2;
        continue;
      } else if (twoChars === 'kh') {
        result += 'ඛ්';
        i += 2;
        continue;
      } else if (twoChars === 'gh') {
        result += 'ඝ්';
        i += 2;
        continue;
      } else if (twoChars === 'ph') {
        result += 'ඵ්';
        i += 2;
        continue;
      } else if (twoChars === 'bh') {
        result += 'භ්';
        i += 2;
        continue;
      } else if (twoChars === 'sh') {
        result += 'ෂ්';
        i += 2;
        continue;
      } else if (twoChars === 'Sh') {
        result += 'ෂ්';
        i += 2;
        continue;
      } else if (twoChars === 'gn') {
        result += 'ඥ්';
        i += 2;
        continue;
      } else if (twoChars === 'jn') {
        result += 'ඥ්';
        i += 2;
        continue;
      } else if (twoChars === 'ng') {
        result += 'ං';
        i += 2;
        continue;
      } else if (twoChars === 'ny') {
        result += 'ඤ්';
        i += 2;
        continue;
      } else if (twoChars === 'nd') {
        result += 'න්ද්';
        i += 2;
        continue;
      } else if (twoChars === 'mb') {
        result += 'ම්බ්';
        i += 2;
        continue;
      } else if (twoChars === 'ru') {
        result += 'රු';
        i += 2;
        continue;
      } else if (twoChars === 'rU') {
        result += 'රූ';
        i += 2;
        continue;
      } else if (twoChars === 'Th') {
        result += 'ථ්';
        i += 2;
        continue;
      } else if (twoChars === 'Dh') {
        result += 'ධ්';
        i += 2;
        continue;
      } else if (twoChars === 'ae') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ැ'; // Add ae sound
        } else {
          result += 'ඇ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'au') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ෞ'; // Add au sound
        } else {
          result += 'ඖ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'aa') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ා'; // Add aa sound
        } else {
          result += 'ආ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'ii') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ී'; // Add ii sound
        } else {
          result += 'ඊ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'ee') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ී'; // Alternative ii sound
        } else {
          result += 'ඊ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'uu') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ූ'; // Add uu sound
        } else {
          result += 'ඌ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'oo') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ෝ'; // Add oo sound
        } else {
          result += 'ඕ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'ai') {
        if (result.length > 0 && result[result.length - 1] === '්') {
          result = result.substring(0, result.length - 1) + 'ෛ'; // Add ai sound
        } else {
          result += 'ඓ';
        }
        i += 2;
        continue;
      } else if (twoChars === 'kr') {
        result += 'ක්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'gr') {
        result += 'ග්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'pr') {
        result += 'ප්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'br') {
        result += 'බ්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'fr') {
        result += 'ෆ්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'dr') {
        result += 'ද්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'sr') {
        result += 'ස්‍ර්';
        i += 2;
        continue;
      } else if (twoChars === 'nj') {
        result += 'ඤ්ජ්';
        i += 2;
        continue;
      } else if (twoChars === 'dw') {
        result += 'ද්ව්';
        i += 2;
        continue;
      } else if (twoChars === 'tw') {
        result += 'ත්ව්';
        i += 2;
        continue;
      }
    }
    
    // Handle regular characters
    const char = chars[i];
    
    // Basic consonants
    if (char === 'k') result += 'ක්';
    else if (char === 'g') result += 'ග්';
    else if (char === 'c') result += 'ච්';
    else if (char === 'j') result += 'ජ්';
    else if (char === 't') result += 'ට්';
    else if (char === 'd') result += 'ඩ්';
    else if (char === 'n') result += 'න්';
    else if (char === 'N') result += 'ණ්';
    else if (char === 'p') result += 'ප්';
    else if (char === 'b') result += 'බ්';
    else if (char === 'm') result += 'ම්';
    else if (char === 'y') result += 'ය්';
    else if (char === 'r') result += 'ර්';
    else if (char === 'l') result += 'ල්';
    else if (char === 'L') result += 'ළ්';
    else if (char === 'v' || char === 'w') result += 'ව්';
    else if (char === 's') result += 'ස්';
    else if (char === 'h') result += 'හ්';
    else if (char === 'f') result += 'ෆ්';
    else if (char === 'T') result += 'ඨ්';
    else if (char === 'D') result += 'ඪ්';
    else if (char === 'G') result += 'ඟ්';
    else if (char === 'J') result += 'ඣ්';
    else if (char === 'C') result += 'ඡ්';
    else if (char === 'P') result += 'ඵ්';
    else if (char === 'B') result += 'භ්';
    else if (char === 'S') result += 'ශ්';
    else if (char === 'K') result += 'ඛ්';
    else if (char === 'M') result += 'ඹ්';
    else if (char === 'H') result += 'ඃ'; // Visargaya
    else if (char === 'Q') result += 'ඞ්'; // Kantaja naasikyaya (ඞ)
    else if (char === 'q') result += 'ං'; // Anusvaraya (ං)
    else if (char === 'z') result += 'ඣ්';
    else if (char === 'Z') result += 'ෂ්';
    else if (char === 'x') result += 'ක්ස්';
    else if (char === 'X') result += 'ක්ෂ්';
    
    // Handle vowels and vowel modifiers
    else if (char === 'a') {
      // Check if it's part of a consonant or standalone
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1); // Remove hal kirima
      } else {
        result += 'අ';
      }
    }
    else if (char === 'A') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ා'; // Add aa sound
      } else {
        result += 'ආ';
      }
    }
    else if (char === 'i') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ි'; // Add i sound
      } else {
        result += 'ඉ';
      }
    }
    else if (char === 'I') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ී'; // Add ii sound
      } else {
        result += 'ඊ';
      }
    }
    else if (char === 'u') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ු'; // Add u sound
      } else {
        result += 'උ';
      }
    }
    else if (char === 'U') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ූ'; // Add uu sound
      } else {
        result += 'ඌ';
      }
    }
    else if (char === 'e') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ෙ'; // Add e sound
      } else {
        result += 'එ';
      }
    }
    else if (char === 'E') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ේ'; // Add ee sound
      } else {
        result += 'ඒ';
      }
    }
    else if (char === 'o') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ො'; // Add o sound
      } else {
        result += 'ඔ';
      }
    }
    else if (char === 'O') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ෝ'; // Add oo sound
      } else {
        result += 'ඕ';
      }
    }
    // Special vowels
    else if (char === 'R') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ෘ'; // Add R sound (vocalic r)
      } else {
        result += 'ඍ';
      }
    }
    else if (char === 'F') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ෲ'; // Add RR sound (long vocalic r)
      } else {
        result += 'ෲ';
      }
    }
    else if (char === 'V') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ෟ'; // Add lu sound
      } else {
        result += 'ඏ';
      }
    }
    else if (char === 'W') {
      if (result.length > 0 && result[result.length - 1] === '්') {
        result = result.substring(0, result.length - 1) + 'ෳ'; // Add luu sound
      } else {
        result += 'ඐ';
      }
    }
    // Handle special characters
    else if (char === '.') {
      result += '।'; // Sinhala full stop
    }
    else if (char === '`') {
      result += '්‍'; // ZWNJ (Zero Width Non-Joiner)
    }
    // Handle numerals (keep as is)
    else if ('0123456789'.includes(char)) {
      result += char;
    }
    // Handle the virama (hal kirima) explicitly
    else if (char === '/') {
      if (result.length > 0 && result[result.length - 1] !== '්') {
        result += '්';
      }
    }
    // Handle any other character as is
    else {
      result += char;
    }
    
    i++;
  }
  
  return result;
};

  // Handle input change with conversion
  const handleInputChange = (e) => {
    const singlishText = e.target.value;
    const sinhalaText = convertSinglishToSinhala(singlishText);
    setWord(sinhalaText);
  };

  const generateImage = async () => {
    setIsGenerating(true);
    try {
      const response = await fetch("/.netlify/functions/wordGen");
      if (!response.ok) {
        throw new Error("Failed to generate image");
      }
      const data = await response.json();
      const fullImageUrl = data.image_url;

      Swal.fire({
        text: "⚠️ Disclaimer: This content is generated by an Algorithm 🤖, and is intended solely for informational or entertainment purposes.",
        imageUrl: fullImageUrl,
        imageAlt: "Generated word",
        customClass: {
          backdrop: "alert-backdrop",
          popup: "alert",
          confirmButton: "alert-btn",
          cancelButton: "alert-btn",
          image: "custom-image-size",
        },
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "⬇️ Download",
        cancelButtonText: "Close",
        preConfirm: () => {
          const link = document.createElement("a");
          link.href = fullImageUrl;
          link.target = "_blank";
          link.click();
        },
        cancelButtonAriaLabel: "Close",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "⚠️ API calls exceeded, try again next minute!",
        customClass: {
          backdrop: "alert-backdrop",
          popup: "alert",
          confirmButton: "alert-btn",
          cancelButton: "alert-btn",
        },
        confirmButtonText: "Try Again",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const generateCustomImage = async () => {
    if (!word.trim()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please enter a word before generating the image!",
        customClass: {
          backdrop: "alert-backdrop",
          popup: "alert",
          confirmButton: "alert-btn",
        },
      });
      return;
    }

    setIsCustomGenerating(true);
    try {
      const response = await fetch("/.netlify/functions/wordGenCustom", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ word }),
      });

      if (!response.ok) {
        throw new Error("Failed to generate image");
      }

      const data = await response.json();
      const fullImageUrl = data.image_url;

      Swal.fire({
        text: "⚠️ Disclaimer: This content is generated by an Automated Bot 🤖, and is intended solely for informational or entertainment purposes.",
        imageUrl: fullImageUrl,
        imageAlt: "Generated word",
        customClass: {
          backdrop: "alert-backdrop",
          popup: "alert",
          confirmButton: "alert-btn",
          cancelButton: "alert-btn",
          image: "custom-image-size",
        },
        focusConfirm: false,
        showCancelButton: true,
        confirmButtonText: "⬇️ Download",
        cancelButtonText: "Close",
        preConfirm: () => {
          const link = document.createElement("a");
          link.href = fullImageUrl;
          link.target = "_blank";
          link.click();
        },
        cancelButtonAriaLabel: "Close",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "⚠️ Please Enter a Valid Sinhala Word! 3 Letters Max!",
        customClass: {
          backdrop: "alert-backdrop",
          popup: "alert",
          confirmButton: "alert-btn",
          cancelButton: "alert-btn",
        },
        confirmButtonText: "Try Again",
      });
    } finally {
      setIsCustomGenerating(false);
    }
  };

  return (
    <div className="wordgen-section">
<Helmet>
  <title>Nisal Herath - Word Generation</title>
  <meta
    name="description"
    content="Discover a powerful free API for generating random Sinhala words and creating dynamic images. Built with Python, FastAPI, and advanced linguistic algorithms for developers and language enthusiasts."
  />
  <meta
    name="keywords"
    content="Sinhala API, word generation API, image creation API, free API, Python API, FastAPI, language technology, word generator, Sinhala language, dynamic image API"
  />
  <meta name="author" content="Nisal Herath" />
  
  {/* Open Graph / Facebook */}
  <meta property="og:type" content="article" />
  <meta property="og:title" content="Sinhala Word API: Innovative Word and Image Generation" />
  <meta
    property="og:description"
    content="Explore a cutting-edge Sinhala Word API that generates random words and creates unique images using advanced Python techniques. Perfect for developers and language projects."
  />
  <meta
    property="og:image"
    content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp"
  />
  <meta property="og:url" content="https://nisal.lk/blog/sinhala-word-api" />
  
  {/* Twitter */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Sinhala Word API: Innovative Word and Image Generation" />
  <meta
    name="twitter:description"
    content="Discover a free API that generates random Sinhala words and creates dynamic images. Built with Python, FastAPI, and advanced linguistic algorithms."
  />
  <meta
    name="twitter:image"
    content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp"
  />
  
  {/* Structured Data - Article */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "Sinhala Word API: Dynamic Word & Image Generation",
      "author": {
        "@type": "Person",
        "name": "Nisal Herath"
      },
      "datePublished": "2024-03-26",
      "image": "https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp",
      "publisher": {
        "@type": "Organization",
        "name": "Nisal Herath Portfolio",
        "logo": {
          "@type": "ImageObject",
          "url": "https://nisal.lk/logo.png"
        }
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://nisal.lk/blog/sinhala-word-api"
      },
      "description": "A comprehensive guide to a powerful Sinhala Word API that generates random words and creates dynamic images using Python, FastAPI, and advanced linguistic techniques."
    })}
  </script>
</Helmet>

      <div className="wordgen-main">
        <div className="wordgen">
          <Link
            to="/"
            className="backhome3"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back Home
          </Link>

          <motion.div
            className="wordgen-grid"
            initial={{ opacity: 0, y: -50 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.1 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="wordgen-text">
              Random 3 Letter Sinhala Word Generator
            </h1>
            <div className="wordgen-window">
              <div
                className={`wizzard ${isGenerating || isCustomGenerating ? "wizzanimating" : ""}`}
              >
                <h1>🕵🏻</h1>
              </div>
            </div>

            <div className="wordgen-btn">
              <button
                onClick={generateImage}
                disabled={isGenerating}
                className="wordgen-button"
              >
                {isGenerating ? "Generating..." : "Generate"}
              </button>
            </div>

            <p className="ortext">or</p>

            <div className="unsplash-disclaimer">
              <p>
                ⚠️ This Input Area only Supports{" "}
                <a
                  href="https://www.helakuru.lk/keyboard"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="unsplash-link"
                >
                  Singlish
                </a>{" "}
                 typing.
              </p>
            </div>

            <div className="custom-gen">
              <div className="wordgen-input">
                <input
                  type="text"
                  value={word}
                  onChange={handleInputChange}
                  placeholder="Enter a custom word"
                  disabled={loading}
                />
              </div>

              <div className="wordgen-btn">
                <button
                  onClick={generateCustomImage}
                  disabled={isCustomGenerating}
                  className="wordgen-button"
                >
                  {isCustomGenerating ? "Generating..." : "Generate Custom"}
                </button>
              </div>
            </div>
            <div className="unsplash-disclaimer">
              <p>
                ⚠️ Disclaimer: This content is generated by an{" "}
                <a
                  href="https://github.com/nisalherath/SinhalaImageAPI"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="unsplash-link"
                >
                  Algorithm 🤖
                </a>
                , and is intended solely for informational or entertainment
                purposes.
              </p>
            </div>

            <div className="poweredwordgen">
              <p>
                Powered by{" "}
                <a
                  href="https://web.facebook.com/RandomSinhalaWord"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SinhalaBot API
                </a>
              </p>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default WordGen;