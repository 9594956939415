import { motion } from "framer-motion";
import "./Avatar.css";

const Avatar = () => {
  return (
    <motion.div className="avatar-container"
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 0.8, ease: "easeOut" }}>
      <motion.div
        className="liquid-border"
        animate={{
          scale: [1, 1.1, 1],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />
      <img
        src="https://res.cloudinary.com/dlnhogbjy/image/upload/v1741035854/3653cd860f023f18ded494b801b534d4esdgfghg_gswhk3.webp"
        alt="Profile"
        className="avatar-img"
      />
    </motion.div>
  );
};

export default Avatar;
