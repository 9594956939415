import React from "react";
import { motion } from "framer-motion";
import Footer from "../../../Footer/Footer";
import Pacman from "../../../Assets/SVG/Pacman";
import "./Blog3.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-tooltip/dist/react-tooltip.css";
import "react-circular-progressbar/dist/styles.css";

import github from "../../../Assets/github.svg";

const UniqueImage =
  "https://nisalintro.vercel.app/api/MyName";

const SolarSystem =
  "https://github-card-orpin.vercel.app/api/githubCard";

const NisalGH =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1743013239/nisalherathNisalHerath-GH_vmrpwe.gif";

const SvgCodeImage =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1743013674/svg_code_un4pqr.webp";

  const ChristmasCard =
  "https://merryxmas-fawn.vercel.app/api/christmasCard";



const blog = () => {
  const slideInFromRight = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <section id="blog" className="blog-main">
<Helmet>
  <title>HTML in SVG: Dynamic Image Rendering | Nisal Herath - Blog</title>
  
  <meta
    name="keywords"
    content="HTML in SVG, SVG HTML, dynamic SVG, SVG rendering, web graphics, frontend development, responsive design, GitHub README, image generation, web image techniques, SVG foreignObject, interactive SVG, HTML embedding, dynamic images, web visualization, SVG programming, image manipulation, web design, frontend tricks, SVG technology, web development, image rendering, interactive graphics, SVG HTML integration, cutting-edge web design, dynamic component rendering, web image generation, SVG techniques, HTML inside SVG, frontend graphics, web visualization methods, SVG image creation, interactive web design, HTML embedding in SVG, dynamic graphic rendering, web image tricks, SVG programming techniques, frontend image generation, responsive SVG, HTML SVG integration, web graphics innovation, dynamic image manipulation, SVG web design, HTML image embedding, interactive frontend techniques, web visualization strategies, SVG rendering methods, dynamic web graphics, HTML SVG tricks, frontend image rendering, web design innovation, SVG image techniques, HTML dynamic images, interactive web graphics, frontend SVG programming, web image integration, SVG responsive design, HTML image generation, dynamic web visualization, frontend graphics techniques, SVG web development, HTML interactive images, web design methods, SVG image manipulation, frontend visualization strategies, HTML dynamic rendering, web graphics programming, SVG interactive techniques, frontend image tricks, HTML web design, SVG visualization methods, dynamic image integration, web development innovation, HTML frontend graphics, SVG responsive techniques, interactive web rendering, frontend image generation, HTML SVG web design, dynamic graphics programming, web visualization innovation, SVG frontend tricks, HTML interactive rendering, web design techniques, SVG image generation, frontend dynamic graphics, HTML web visualization, interactive SVG rendering, web graphics programming techniques"
  />

  <meta
    name="description"
    content="Unlock groundbreaking SVG techniques: Master embedding HTML inside SVGs, create revolutionary dynamic interactive images, transform web graphics with cutting-edge frontend development methods. Comprehensive guide for developers, designers, and web innovators exploring advanced image rendering technologies."
  />

  <meta name="author" content="Nisal Herath" />

  {/* Open Graph / Facebook */}
  <meta property="og:type" content="article" />
  <meta
    property="og:title"
    content="HTML in SVG: Ultimate Guide to Dynamic Image Rendering | Revolutionize Web Graphics in 2024"
  />
  <meta
    property="og:description"
    content="Discover groundbreaking techniques to embed HTML inside SVGs, create mind-blowing dynamic interactive images, and transform web graphics with innovative frontend development strategies. The definitive guide for modern web creators."
  />
  <meta
    property="og:image"
    content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp"
  />
  <meta
    property="og:url"
    content="https://nisal.lk/blog/blog"
  />
  <meta property="og:site_name" content="Nisal Herath - Web Innovation Hub" />

  {/* Twitter */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta
    name="twitter:title"
    content="🔥 HTML in SVG: Breakthrough Web Graphics Technique | Frontend Mastery 2024"
  />
  <meta
    name="twitter:description"
    content="Explosive tutorial revealing how to embed HTML inside SVGs! Create dynamic, interactive images that redefine web design. Must-read for frontend innovators!"
  />
  <meta
    name="twitter:image"
    content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp"
  />
  <meta name="twitter:creator" content="@NisalHerath" />

  {/* Structured Data */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": ["TechArticle", "HowTo", "CreativeWork"],
      "name": "Mastering HTML in SVG: Revolutionary Dynamic Image Rendering Techniques",
      "headline": "Comprehensive Guide to Embedding HTML in SVG: Transforming Web Graphics",
      "author": {
        "@type": "Person",
        "name": "Nisal Herath",
        "jobTitle": "Frontend Developer",
        "worksFor": {
          "@type": "Organization",
          "name": "Web Innovation Studio"
        }
      },
      "datePublished": "2024-03-26",
      "dateModified": "2024-03-26",
      "image": "https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp",
      "publisher": {
        "@type": "Organization",
        "name": "Nisal Herath Portfolio",
        "logo": {
          "@type": "ImageObject",
          "url": "https://nisal.lk/logo.png"
        }
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://nisal.lk/blog/blog"
      },
      "description": "Definitive guide exploring advanced techniques for embedding HTML inside SVGs, creating dynamic interactive images, and revolutionizing web graphics with cutting-edge frontend development strategies.",
      "keywords": "HTML in SVG, dynamic SVG rendering, web graphics, frontend development",
      "about": {
        "@type": "Thing",
        "name": "Web Graphics Technology"
      },
      "audience": {
        "@type": "Audience",
        "audienceType": ["Web Developers", "Frontend Engineers", "Graphic Designers"]
      }
    })}
  </script>

  {/* SEO Meta Tags */}
  <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large" />
  <meta name="googlebot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
  <meta name="bingbot" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />

  {/* Canonical Link */}
  <link rel="canonical" href="https://nisal.lk/blog/blog" />
  <link rel="alternate" hrefLang="en" href="https://nisal.lk/blog/blog" />

  {/* Language and Geo Targeting */}
  <meta httpEquiv="content-language" content="en-US" />
  <meta name="geo.region" content="US" />
  <meta name="geo.placename" content="United States" />
  <meta name="ICBM" content="37.0902, -95.7129" />

  {/* Performance and Accessibility Hints */}
  <meta name="format-detection" content="telephone=no" />
  <meta name="google" content="nositelinkssearchbox" />
</Helmet>

      <div className="blog-component">
        <div className="blog">
          <Link
            to="/blog"
            className="backhome55"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back to Blog
          </Link>

          <div className="blogcard">
            <motion.section
              className="blog-appear"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="blog-item item1">
                <h1>
                  {" "}
                  <Pacman /> HTML inside SVGs - How to serve HTML/CSS inside an
                  Image{" "}
                </h1>
              </div>

              <div className="blog-item item6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={NisalGH}
                  alt="Github Screenshot"
                  className="illustrator-image"
                  title="Github Profile SS"
                  style={{ cursor: "pointer" }}
                />
              </motion.div>
                <article>
                <p>
                  Recently, I saw some person had a{" "}
                  <a
                    href="https://github.com/nisalherath"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="unsplash-link"
                  >
                    Dynamic profile description
                  </a>{" "}
                  on GitHub. Then when I look through the <samp>ReadMD</samp>,
                  file that had that Animated Description on, it was just an
                  Image shown inside a ReadMD file. Obviously I knew that you
                  could put animated GIFs on the ReadMDs to showcase animations.
                  But this one was different in a way that it had properties
                  that a traditional GIF could not possibly have.
                </p>
                </article>
              </div>
            </motion.section>

            <motion.section
              className="blog-appear"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="blog-item item3">
                <p>
                  If you are interested to learn about this project, You can
                  find the code on Github right down Below ⬇️
                </p>
                <div className="button-container">
                  <a
                    href="https://github.com/nisalherath/HTML-inside-SVG"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="githubbtn githubbtn11"
                    aria-label="Visit on Instagram"
                    style={{ textDecoration: "none" }}
                  >
                    <img src={github} className="githubimg" alt="github" />
                    The Code
                  </a>
                </div>
              </div>
            </motion.section>

            <div className="blog-item item4">
              <h1>
                <Pacman /> Unique Image Type?
              </h1>
            </div>

            <div className="blog-item item6">
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
                className="unique-image-container"
              >
                <p>This is an Actual SVG that has Dynamic Text Elements.</p>
                <img
                  src={UniqueImage}
                  alt="UniqueImage Type"
                  className="Uniqueimage"
                  title="Yes, This is an Actual Image"
                />
                </motion.div>

              <article>
                That <b>Image</b> I saw on someone's Github profile had some{" "}
                <em>unique</em> characteristics on it.
                <p>The Image had {">>>"}</p>
                <p>
                  <samp>1.Text wrapping</samp> – The text inside this Image was
                  wrapping and adjusting according to the{" "}
                  <em>browser window Size</em>.
                </p>
                <p>
                  <samp>2.Scaling Elements</samp> – Some elements of the Image,
                  actually grew and shrink according to the{" "}
                  <em>browser window Size</em>.
                </p>
                <p>
                  <samp>3.Animations/Effects</samp> – The image Background was
                  changing colors every few seconds.
                </p>
              </article>
            </div>

            <div className="blog-item item4">
              <h1>
                <Pacman /> Cloning the Image
              </h1>
            </div>

            <div className="blog-item item6">
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={SolarSystem}
                  alt="Solar Example"
                  className="solarsystem"
                  title="No, This is not a GIF"
                  style={{ cursor: "pointer" }}
                />
                </motion.div>

              <article>
                I then thoroughly look for an answer on the Internet on how to
                do it? how do I render Images with <em>Dynamic Components</em>{" "}
                inside of it? No hit whatsoever, all were directing me to How to
                create a GIF or How to animate mp4? I was exhausted at this
                point, looking through every single search query to find out a
                way.
              </article>

              <article>
                Finally, something came to my mind. What if I had an{" "}
                <samp>SVG</samp> to do all this? Me being a{" "}
                <b>
                  <em>Frontend Junky</em>
                </b>{" "}
                I knew I could whip out an SVG to do all of that. But then I ran
                it to a problem. In order to show text inside an SVG, you have
                to make an outline of shapes to show Text.{" "}
                <em>That was not gonna work,</em> since I wanted text wrapping
                inside my Image according to the width of my screen size{" "}
                <b>
                  (This means I'm not rendering Text inside my SVG, just Shapes
                  as Letters)
                </b>
                . So, that was not gonna work. Then I spent the next few hours
                researching how to render Actual Text inside an SVG that has
                real text properties, like "Word Wrapping".
              </article>
            </div>

            <div className="blog-item item4">
              <h2>HTML inside the SVG?</h2>
            </div>

            <div className="blog-item item6">
              <article>
                Boom!!! What if I add HTML to my SVG & render that HTML inside
                the SVG??? I'm shocked that no one talks about this on any
                platform, & you could <b>Actually render HTML as an Image!!!</b>{" "}
                After some research on the Internet, the solution was right
                there in front of me. It is to use{" "}
                <code>
                  <kbd>{"<foreignObject>"}</kbd>
                </code>{" "}
                inside the SVG.
              </article>
            </div>

            <div className="blog-item item4">
              <h2>Peocess of writing HTML inside the SVG</h2>
            </div>

            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={ChristmasCard}
                  alt="ChristmasCard"
                  className="Christmascard"
                  title="No, This is not a GIF"
                  style={{ cursor: "pointer" }}
                  width={230}
                  height={350}
                />
                </motion.div>
            <div className="blog-item item6">
              <article>
                Then the fun began. I created some HTML to showcase a{" "}
                <b>Christmas Card</b> as a Profile card that shows an Animated
                Christmas Card for the Holidays. This was created using pretty
                basic HTML{" "}
                <code>
                  <kbd>{"<div>"}</kbd>
                </code>{" "}
                's and some clever CSS styling added to those{" "}
                <code>
                  <kbd>{"<div>"}</kbd>
                </code>{" "}
                elements. After that, I tried saving and opening the SVG I
                created that had HTML inside the{" "}
                <code>
                  <kbd>{"<foreignObject>"}</kbd>
                </code>{" "}
                tag. Nothing was rendering?
              </article>
            </div>

            <div className="blog-item item4">
              <h2>Helper Function to Load the SVG</h2>
            </div>

            <div className="blog-item item6">
              <article>
                I was not able to render HTML inside an SVG, just by adding a{" "}
                <code>
                  <kbd>{"<foreignObject>"}</kbd>
                </code>{" "}
                tag. Then I was back to researching and troubleshooting.
                Solution was to create a{" "}
                <b>
                  <samp>JavaScript Helper</samp>
                </b>{" "}
                that Helps render the HTML inside the SVG. For my specific use
                case, I need to render the image inside a browser so, that would
                allow me to use that image in the{" "}
                <em>ReadMD file in GitHub repos.</em>
              </article>
            </div>

            <div className="blog-item item4">
              <h1>
                <Pacman /> Serving the HTML infused SVG on the Web
              </h1>
            </div>

            <div className="blog-item item6">
            <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={SvgCodeImage}
                  alt="HTML inside SVG Code"
                  className="illustrator-image"
                  title="HTML inside SVG Code"
                  style={{ cursor: "pointer" }}
                />
              </motion.div>

              <article>
                Starting that, if I wanted to get an SVG to load onto my ReadMD
                I would have to serve this SVG on{" "}
                <code>
                  <kbd>HTTP</kbd>
                </code>
                . To handle and serve the SVG on HTTP requires a <b>server</b>,
                in this case I would have to setup a{" "}
                <samp>
                  <b>Next.js</b>
                </samp>{" "}
                API router. This is fairly simple and really easy to implement.
                This only took few steps to setup & you're good to go.
                <p>
                  <samp>1.The Main Function</samp> – This has the API
                  routes(HTTP/S) defined in Next.js.
                </p>
                <p>
                  <samp>2.Set the Content Type Header</samp> – Since we're
                  trying to serve an Image, this tells the browser it's an SVG
                  that we're serving.
                </p>
                <p>
                  <samp>3.Setup the SVG content as a String</samp> – Entire SVG
                  is stored as a JavaScript string.
                </p>
                <p>
                  <samp>4.HTML goes in the </samp>{" "}
                  <code>
                    <kbd>{"<foreignObject>"}</kbd>
                  </code>
                  <samp>tag</samp> – This embeds the HTML, inside the SVG.
                </p>
                <p>
                  <samp>5.Respond with a status 200 code</samp> – The response
                  is sent with an HTTP 200 status (OK).
                </p>
              </article>
            </div>

            <div className="blog-item item4">
              <h1>
                <Pacman /> The Results
              </h1>
            </div>

            <div className="blog-item item6">
              <article>
                Once the server is created to serve that SVG in <b>HTTP</b>, it
                finally rendered on my Browser window!!! So finally I can
                showcase a <samp>Dynamic Image</samp> on my GitHub ReadMD. In
                order to serve the SVG requests on the <em>Actual Web</em>, I
                used <kbd>Vercel</kbd> to host my Next.js server. This was
                fairly simple & make sure to have the API endpoint that serves
                the SVG on <b>public</b> so, that if I call that endpoint on the
                Internet it would always serve me the SVG.
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default blog;
