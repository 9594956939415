import React, { useEffect, useState, useCallback, memo, useRef } from "react";
import "./WallGallery.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import UnsplashLogo from "../Assets/unsplash.svg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FiDownload } from "react-icons/fi";
import Masonry from "react-masonry-css";
import { Helmet } from "react-helmet";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Memoized image component with animations
const GalleryImage = memo(({ image, onDownload, index }) => {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  
  // Stagger the animation delay based on item index, but cap it
  const delay = Math.min(index * 0.05, 1); // Cap the maximum delay to 1 second
  
  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);
          observer.unobserve(currentRef);
        }
      },
      { rootMargin: "200px", threshold: 0.1 }
    );
    
    observer.observe(currentRef);
    
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);
  
  // Only apply animations after image is in view
  const itemStyle = {
    transform: isInView ? "translateY(0)" : "translateY(20px)",
    opacity: isInView ? 1 : 0,
    transition: `opacity 0.4s ease-out ${delay}s, transform 0.4s ease-out ${delay}s`,
    visibility: imageLoaded || !isInView ? "visible" : "hidden",
    height: !imageLoaded && isInView ? "300px" : "auto" // Reserve space while loading
  };
  
  return (
    <div 
      ref={ref}
      className="gallery-item"
      style={itemStyle}
    >
      <a
        href={image.link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={image.alt || "View full image on Unsplash"}
      >
        <div className="image-container">
          <LazyLoadImage
            src={image.src}
            alt={image.alt || "Wall Image"}
            effect="blur"
            wrapperClassName="gallery-item"
            threshold={200}
            beforeLoad={() => setImageLoaded(false)}
            afterLoad={() => setImageLoaded(true)}
            placeholder={
              <div style={{ 
                background: "#f0f0f0", 
                width: "100%", 
                height: "300px",
                borderRadius: "5px"
              }} />
            }
            style={{
              width: "100%",
              objectFit: "cover",
              borderRadius: "5px",
              transition: "transform 0.3s ease",
            }}
          />
          <div className="image-items-unsplash">
            <div className="author-name">
              {image.author ? `Photo by ${image.author}` : "Author Unknown"}
            </div>
            <div
              className="download-icon"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onDownload(image.src, image.alt || `unsplash-wallpaper-${index}`);
              }}
            >
              <FiDownload size={24} />
            </div>
          </div>
        </div>
      </a>
    </div>
  );
});

// Optimization: Avoid re-renders by storing static content
const headerVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0 },
};

const TitleHeader = memo(() => (
  <div className="titlediv2">
    <div className="Wally-title">
      <Link
        to="/"
        className="backhome3"
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <div className="arrowhead2">
          <div className="arrowline12"></div>
          <div className="arrowline22"></div>
          <div className="arrowmainline2"></div>
        </div>
        Back Home
      </Link>

      <div className="poweredbyunsplash">
        <p>Powered By Unsplash</p>
        <a
          href="https://unsplash.com"
          target="_blank"
          rel="noopener noreferrer"
          className="unsplash-link"
        >
          <img
            src={UnsplashLogo}
            className="unsplashlogomain"
            alt="Unsplash Logo"
          />
        </a>
      </div>

      <motion.h1
        className="Wally-title2"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
        variants={headerVariants}
      >
        Lets Doom-Scroll Images
      </motion.h1>

      <div className="unsplash-disclaimer">
        <p>
          All images displayed in this gallery are sourced from{" "}
          <a
            href="https://unsplash.com"
            target="_blank"
            rel="noopener noreferrer"
            className="unsplash-link"
          >
            Unsplash
          </a>
          , used under their license for free and commercial purposes with
          proper credit.
          <br />
          Photographer credits are included with each image. For concerns, feel
          free to contact me.
        </p>
      </div>
    </div>
  </div>
));

const WallGallery = () => {
  const [images, setImages] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const loadingRef = useRef(false);
  const observer = useRef(null);
  const loadingElementRef = useRef(null);
  const initialLoadDone = useRef(false);
  const retryCount = useRef(0);
  const fetchingPageRef = useRef(null);

  // More responsive column layout
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    900: 3,
    700: 2,
    500: 1
  };

  // Improved debounce with immediate option
  const debounce = (func, wait, immediate = false) => {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const fetchImages = useCallback(async (pageNum) => {
    // Prevent duplicate fetches of the same page
    if (loadingRef.current || fetchingPageRef.current === pageNum) return;
    
    fetchingPageRef.current = pageNum;
    loadingRef.current = true;
    setLoading(true);
    
    try {
      const response = await fetch(
        `/.netlify/functions/wallpaperFetch?pageNumber=${pageNum}&timestamp=${Date.now()}`
      );

      if (!response.ok) {
        throw new Error(`HTTP Error! Status: ${response.status}`);
      }

      const data = await response.json();

      if (!Array.isArray(data)) {
        throw new Error("Unexpected data format received.");
      }

      // Reset retry count on success
      retryCount.current = 0;
      
      setImages((prevImages) => {
        // More efficient duplicate filtering with Map
        const newImagesMap = new Map();
        
        // Add existing images to map
        prevImages.forEach(img => {
          if (img.src) {
            newImagesMap.set(img.src, img);
          }
        });
        
        // Add new unique images
        data.forEach(img => {
          if (img.src && !newImagesMap.has(img.src)) {
            newImagesMap.set(img.src, img);
          }
        });
        
        // Convert map back to array
        return Array.from(newImagesMap.values());
      });
      
      // Mark initial load as complete
      if (!initialLoadDone.current && data.length > 0) {
        initialLoadDone.current = true;
      }
    } catch (error) {
      console.error("Error fetching images:", error);
      
      // Retry logic with exponential backoff
      if (retryCount.current < 3) {
        const backoffTime = Math.pow(2, retryCount.current) * 1000;
        console.log(`Retrying in ${backoffTime}ms...`);
        retryCount.current++;
        
        setTimeout(() => {
          fetchingPageRef.current = null;
          fetchImages(pageNum);
        }, backoffTime);
      }
    } finally {
      // Only clear loading state if we're not retrying
      if (retryCount.current === 0) {
        setLoading(false);
        loadingRef.current = false;
        fetchingPageRef.current = null;
      }
    }
  }, []);

  // Better intersection observer with larger margins
  useEffect(() => {
    if (loadingElementRef.current) {
      observer.current = new IntersectionObserver(
        debounce((entries) => {
          const [entry] = entries;
          if (entry.isIntersecting && !loadingRef.current) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        }, 300),
        { rootMargin: "500px 0px" }
      );

      observer.current.observe(loadingElementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  // Fetch images when page number changes
  useEffect(() => {
    fetchImages(pageNumber);
  }, [pageNumber, fetchImages]);

  // Pre-load next page when scrolling nears bottom
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (loading || !initialLoadDone.current) return;
      
      const scrollPosition = window.innerHeight + window.scrollY;
      const bodyHeight = document.body.offsetHeight;
      
      // If we're 80% down the page, prefetch the next page
      if (scrollPosition > bodyHeight * 0.8) {
        fetchImages(pageNumber + 1);
      }
    }, 150);
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, pageNumber, fetchImages]);

  // Properly download image instead of opening in new tab
  const handleDownload = useCallback((imageUrl, filename) => {
    // Create a temporary link for download
    const downloadImage = async (url, name) => {
      try {
        // Show starting download toast
        const toastId = toast.loading('Starting Download...', {
          className: 'custom-toast custom-toast-loading',
        });
        
        // Add attribution parameter for Unsplash
        const imageUrlWithAttribution = `${url}?utm_source=NerdCake&utm_medium=referral&dl=1`;
        
        // Fetch the image with CORS headers
        const response = await fetch(imageUrlWithAttribution, {
          headers: {
            'Origin': window.location.origin
          }
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        
        // Get the blob from response
        const blob = await response.blob();
        
        // Create object URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);
        
        // Create temporary link element
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${name.replace(/[^a-z0-9]/gi, '-').toLowerCase()}.jpg`;
        document.body.appendChild(link);
        
        // Trigger download
        link.click();
        
        // Clean up
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
        
        // Update toast to success
        toast.update(toastId, {
          render: 'Download complete!',
          type: 'success',
          isLoading: false,
          className: 'custom-toast custom-toast-success',
          autoClose: 3000,
        });
      } catch (error) {
        console.error('Download failed:', error);
        
        // Fallback to opening in new tab if download fails
        const newTab = window.open(
          `${url}?utm_source=NerdCake&utm_medium=referral`,
          '_blank',
          'noopener noreferrer'
        );
        if (newTab) newTab.opener = null;
        
        // Show error toast
        toast.warn('Download failed. Image opened in new tab instead.', {
          className: 'custom-toast custom-toast-warning',
        });
      }
    };
    
    downloadImage(imageUrl, filename);
  }, []);

  return (
    <div className="Wall-gallery">
      <Helmet>
        <title>Nisal Herath - Wallpapers</title>
        <meta
          name="description"
          content="Discover an endless stream of high-quality free wallpapers powered by Unsplash. Doom-scroll through stunning images for desktop, mobile, and creative projects with easy downloads."
        />
        <meta
          name="keywords"
          content="unsplash api, free image api, wallpapers, free wallpapers, doom scrolling, pictures, high-resolution images, infinite scroll gallery, desktop backgrounds, mobile wallpapers"
        />
        <meta name="author" content="Your Name" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Wally: Unlimited Free Wallpapers & Image Gallery" />
        <meta
          property="og:description"
          content="Explore an infinite collection of stunning free wallpapers. Powered by Unsplash, featuring easy downloads and endless scrolling for all your visual needs."
        />
        <meta
          property="og:image"
          content="https://yourwebsite.com/wally-og-image.jpg"
        />
        <meta property="og:url" content="https://yourwebsite.com/wall-gallery" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Wally: Free Wallpapers & Infinite Image Gallery" />
        <meta
          name="twitter:description"
          content="Endless high-quality wallpapers at your fingertips. Powered by Unsplash, featuring seamless scrolling and instant downloads."
        />
        <meta
          name="twitter:image"
          content="https://yourwebsite.com/wally-twitter-image.jpg"
        />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebApplication",
            "name": "Wally: Free Wallpapers Gallery",
            "description": "An infinite scrolling image gallery powered by Unsplash, offering free high-quality wallpapers for desktop and mobile.",
            "applicationCategory": "Multimedia",
            "operatingSystem": "All",
            "offers": {
              "@type": "Offer",
              "price": "0",
              "priceCurrency": "USD"
            },
            "url": "https://yourwebsite.com/wall-gallery",
            "image": "https://yourwebsite.com/wally-og-image.jpg"
          })}
        </script>
      </Helmet>

      <TitleHeader />

      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="photo-gallery"
        columnClassName="gallery-column"
      >
        {images.map((image, index) => (
          <GalleryImage 
            key={`${image.src || index}-${index}`}
            image={image}
            onDownload={handleDownload}
            index={index}
          />
        ))}
      </Masonry>
      
      {/* Loading indicator and intersection observer target */}
      <div ref={loadingElementRef} style={{ height: "50px", margin: "20px 0" }}>
        {loading && <div className="loading-unsplash"></div>}
      </div>
      
      {/* Toast Container */}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default WallGallery;