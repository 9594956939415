import React from "react";
import { motion } from "framer-motion";
import Footer from "../../../Footer/Footer";
import Pacman from "../../../Assets/SVG/Pacman";
import "./Blog2.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "react-tooltip/dist/react-tooltip.css";
import "react-circular-progressbar/dist/styles.css";

import github from "../../../Assets/github.svg";

const FastAPIImage =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp";

const WordUtilsImage =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1740543269/Word_Utils_zxxezv.webp";

const CreateImage =
  "https://res.cloudinary.com/dlnhogbjy/image/upload/v1740571337/Create_Image_yu3sek.webp";

const sinhalawords = [
  {
    className: "pic1",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot1_xk8ffb.webp",
  },
  {
    className: "pic2",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot2_h6oxev.webp",
  },
  {
    className: "pic3",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot4_lwdyqs.webp",
  },
];

const blog = () => {
  const slideInFromRight = {
    hidden: { x: 50, opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };

  return (
    <section id="blog" className="blog-main">
<Helmet>
  <title>Sinhala Word API: Dynamic Word & Image Generation | Nisal Herath - Blog</title>
  <meta
    name="description"
    content="Discover a powerful free API for generating random Sinhala words and creating dynamic images. Built with Python, FastAPI, and advanced linguistic algorithms for developers and language enthusiasts."
  />
  <meta
    name="keywords"
    content="Sinhala API, word generation API, image creation API, free API, Python API, FastAPI, language technology, word generator, Sinhala language, dynamic image API"
  />
  <meta name="author" content="Nisal Herath" />
  
  {/* Open Graph / Facebook */}
  <meta property="og:type" content="article" />
  <meta property="og:title" content="Sinhala Word API: Innovative Word and Image Generation" />
  <meta
    property="og:description"
    content="Explore a cutting-edge Sinhala Word API that generates random words and creates unique images using advanced Python techniques. Perfect for developers and language projects."
  />
  <meta
    property="og:image"
    content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp"
  />
  <meta property="og:url" content="https://nisal.lk/blog/sinhala-word-api" />
  
  {/* Twitter */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Sinhala Word API: Innovative Word and Image Generation" />
  <meta
    name="twitter:description"
    content="Discover a free API that generates random Sinhala words and creates dynamic images. Built with Python, FastAPI, and advanced linguistic algorithms."
  />
  <meta
    name="twitter:image"
    content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp"
  />
  
  {/* Structured Data - Article */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Article",
      "headline": "Sinhala Word API: Dynamic Word & Image Generation",
      "author": {
        "@type": "Person",
        "name": "Nisal Herath"
      },
      "datePublished": "2024-03-26",
      "image": "https://res.cloudinary.com/dlnhogbjy/image/upload/v1740537042/FastAPI_phyils.webp",
      "publisher": {
        "@type": "Organization",
        "name": "Nisal Herath Portfolio",
        "logo": {
          "@type": "ImageObject",
          "url": "https://nisal.lk/logo.png"
        }
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://nisal.lk/blog/sinhala-word-api"
      },
      "description": "A comprehensive guide to a powerful Sinhala Word API that generates random words and creates dynamic images using Python, FastAPI, and advanced linguistic techniques."
    })}
  </script>
</Helmet>

      <div className="blog-component">
        <div className="blog">
          <Link
            to="/blog"
            className="backhome55"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="arrowhead2">
              <div className="arrowline12"></div>
              <div className="arrowline22"></div>
              <div className="arrowmainline2"></div>
            </div>
            Back to Blog
          </Link>

          <div className="blogcard">
            <motion.section
              className="blog-appear"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="blog-item item1">
                <h1>
                  {" "}
                  <Pacman /> Custom API: Random 3-Letter Word Generator
                  (Image){" "}
                </h1>
              </div>

              <div className="blog-item item2">
                <p>
                  I created this API as a side projects that takes ,my already
                  existing project of{" "}
                  <a
                    href="https://x.com/sinhala333"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="unsplash-link"
                  >
                    Sinhala Bot on SM
                  </a>{" "}
                  and elevate it to have some API endpoints. The API is built
                  with <samp>2 Endpoints ATM</samp>, the first one being{" "}
                  <code>It generates it's own 3 letter word & returns it.</code>{" "}
                  and the second one being{" "}
                  <code>
                    it generates an Image for a word that{" "}
                    <samp>the user provides.</samp>
                  </code>{" "}
                  The API has built in <samp>Rate Limiting</samp> logic so that
                  the Developers that are using this API,{" "}
                  <code>Do not abuse the API Endpoints.</code>
                  Not just Developers, <kbd>Anyone</kbd> can use the API and
                  Generate an Image.
                </p>

                <motion.div
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, amount: 0.1 }}
                  variants={slideInFromRight}
                  transition={{ duration: 1 }}
                >
                  <Swiper
                    effect="coverflow"
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView="auto"
                    coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: false,
                    }}
                    loop={true}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                    }}
                    modules={[EffectCoverflow, Autoplay]}
                    touchRatio={1}
                    simulateTouch={true}
                    className="proart-swiper"
                    style={{
                      width: "350px",
                      height: "auto",
                      outline: "none",
                      borderRadius: "15px",
                    }}
                  >
                    {sinhalawords.map((img, index) => (
                      <SwiperSlide key={index} style={{ outline: "none" }}>
                        <div
                          style={{
                            position: "relative",
                            width: "100%",
                            height: "100%",
                            overflow: "visible",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "10px",
                          }}
                        >
                          <img
                            loading="lazy"
                            src={
                              img.src ||
                              "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot4_lwdyqs.webp"
                            }
                            alt={img.alt || `Slide ${index + 1}`}
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </motion.div>
              </div>
            </motion.section>

            <motion.section
              className="blog-appear"
              initial={{ opacity: 0, y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true, amount: 0.1 }}
              transition={{ duration: 0.6 }}
            >
              <div className="blog-item item3">
                <p>
                  If you are interested to learn about this project, You can
                  find the code on Github right down Below ⬇️
                </p>
                <div className="button-container">
                  <a
                    href="https://github.com/nisalherath/SinhalaImageAPI"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="githubbtn githubbtn11"
                    aria-label="Visit on Instagram"
                    style={{ textDecoration: "none" }}
                  >
                    <img src={github} className="githubimg" alt="github" />
                    The Code
                  </a>
                </div>
              </div>
            </motion.section>

            <div className="blog-item item4">
              <h1>
                <Pacman /> Setting Up the API
              </h1>
            </div>

            <div className="blog-item item4">
              <h2>Using FastAPI</h2>
            </div>

            <div className="blog-item item6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={FastAPIImage}
                  alt="Illustrator Screenshot"
                  className="illustrator-image"
                />
              </motion.div>

              <article>
                <b>Fast API</b> setup is the lightest API Framework, that goes
                with my requirement for this project.
                <p>Here’s a breakdown of how I built it {">>>"}</p>
                <p>
                  <samp>1.Setting Up FastAPI</samp> – Installed FastAPI and
                  Uvicorn to run the server.
                </p>
                <p>
                  <samp>2.Adding Rate Limiting</samp> – To prevent spam, I added
                  a simple IP-based rate limiter.
                </p>
                <p>
                  <samp>3.Creating the</samp> <kbd>/generate</kbd>{" "}
                  <samp>Endpoint</samp> – This endpoint generates a random word,
                  converts it into an image, and returns the image URL.
                </p>
                <p>
                  <samp>4.Serving Static Files</samp> – Since the images are
                  generated dynamically, they need to be accessible from a
                  public directory.
                </p>
              </article>
            </div>

            <div className="blog-item item4">
              <h1>
                <Pacman /> Generating Words and Images
              </h1>
            </div>

            <div className="blog-item item6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={WordUtilsImage}
                  alt="Illustrator Screenshot"
                  className="illustrator-image"
                />
              </motion.div>

              <article>
                The process of creating a logic that generates a{" "}
                <samp>Random Word</samp> and making it a fully{" "}
                <samp>Processed Image</samp> was not that easy. So, for the word
                generation part, I had to go through the{" "}
                <samp>Whole Sinhala Alphabet</samp> to make a logic that would
                create a <kbd>3 Letter Combination</kbd> of words that would
                resemble a sinhala word. Having gone through multiple{" "}
                <samp>Logic Cycles</samp>, the logic would spit out a word that
                goes with the rules of Sinhala Grammer.
              </article>

              <article>
                The <samp>Image Creation</samp> part was bit trickey at first. I
                would go through a generated word and try to create an Image
                from that word & it would sometimes misalign the{" "}
                <kbd>Vowel Signs</kbd> of that Sinhala word. So then to the{" "}
                <code>Stack Overflows😉</code> for an answer. The replies to
                that was all around. Then someone had mentioned when they tried
                to do <samp>Arabic text to Image</samp> and it would also
                misalign the <code>Signs in Arabic</code> & then they found the
                solution to be{" "}
                <a
                  href="https://www.gtk.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="unsplash-link"
                >
                  GTK
                </a>{" "}
                . The <samp>Vowel Misalignment</samp> was gone in the Generated
                Image after I setup GTK in my runtime, Thanks to that Guy on the
                Stack Overflows!
              </article>
            </div>

            <div className="blog-item item4">
              <h2>How the API Generates Random 3-Letter Sinhala Words</h2>
            </div>

            <div className="blog-item item6">
              <article>
                The process starts with the generation of random 3-letter
                Sinhala words. To achieve this, I used a combination of Sinhala
                consonants, vowels, and vowel signs. Here's a brief breakdown of
                how the generation works:
                <p>
                  <samp>1.Consonants & Vowels:</samp> A list of Sinhala
                  consonants and vowels forms the foundation of the word
                  generation. Each word consists of three letters, where:
                  <br></br>
                  <br></br>
                  🔸 The first letter is a consonant.<br></br>
                  🔸 The second letter may be a consonant followed by a vowel
                  sign.<br></br>
                  🔸 The third letter is also a consonant, potentially with or
                  without a vowel sign.<br></br>
                  <br></br>
                </p>
                <p>
                  <samp>2.Two-Letter Combinations:</samp> To introduce more
                  variety and realism to the word generation, I also included a
                  set of valid two-letter combinations that form part of the
                  random words. These two-letter combos are randomly chosen with
                  a 20% probability for the first two letters of the word.
                </p>
                <p>
                  <samp>3.Word Validation:</samp> Once a word is formed, I check
                  if it follows the rules for valid Sinhala words, such as
                  ensuring the second and third letters are not standalone
                  vowels or invalid in specific positions.
                </p>
              </article>
            </div>

            <div className="blog-item item4">
              <h2>
                Turning Text into Images: The Magic Behind Image Generation (GTK
                4 better rendering)
              </h2>
            </div>

            <div className="blog-item item6">
              <article>
                Once we have a random word, the next step is turning this word
                into a visually appealing image. The process involves several
                stages:
                <p>
                  <samp>1.Background Creation:</samp>I generate a vibrant
                  gradient background for each image. This background is dynamic
                  and changes each time a new word is generated, giving each
                  image a fresh look.
                </p>
                <p>
                  <samp>2.Text Rendering:</samp>
                  The generated word is rendered on the image using the Python
                  Imaging Library (PIL). The text is carefully positioned in the
                  center of the image, ensuring it's clearly visible.
                </p>
                <p>
                  <samp>3.Watermarking:</samp>
                  To make the images look professional and traceable, I add a
                  watermark along with version text to each image. This helps
                  prevent unauthorized use and keeps track of the image's
                  version.
                </p>
                <p>
                  <samp>4.Final Image:</samp>
                  The final image is saved as a high-quality JPEG file and
                  stored in a designated directory for easy access.
                </p>
              </article>
            </div>

            <div className="blog-item item4">
              <h1>
                <Pacman /> How I Used Python to Create Word Images Dynamically
              </h1>
            </div>

            <div className="blog-item item6">
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.1 }}
                variants={slideInFromRight}
                transition={{ duration: 1 }}
              >
                <img
                  src={CreateImage}
                  alt="Illustrator Screenshot"
                  className="illustrator-image"
                />
              </motion.div>

              <article>
                The dynamic creation of word images relies on Python’s PIL
                library to handle the image generation process. The{" "}
                <kbd>/create_image</kbd> function orchestrates all steps,
                including creating a vibrant background, rendering the word,
                applying a watermark, and finally saving the image.
                <p>
                  By leveraging the power of Python and PIL, I can easily
                  generate thousands of images, each with a unique look, while
                  ensuring that every image has the correct word and relevant
                  metadata such as a watermark and version number. This system
                  helps automate the process of creating visually appealing
                  content for my social media posting bot, making it a lot
                  easier to generate fresh content on the fly.
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </section>
  );
};

export default blog;
