import React, { memo, useMemo } from 'react';
import './Experience.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const AutomationPost = ({ automationImages, currentIndexAutomation }) => {
  const memoizedImages = useMemo(() => automationImages, [automationImages]);

  return (
    <div
      className="automationpc"
      alt="Desktop"
      data-tooltip-id="automationpc-tooltip"
      data-tooltip-content="Desktop"
    >
      <ReactTooltip
        id="automationpc-tooltip"
        effect="solid"
        place="top"
        className="custom-tooltip"
        delayShow={100}
        delayHide={100}
        arrowColor="rgba(31, 22, 83, 0.7)"
      />

      {/* Just Posted Animation */}
      <div className="justposted2 fade-in">
        <p>Sinhala Bot Just Posted+</p>
      </div>

      {/* Automation Post */}
      <div
        key={currentIndexAutomation}
        className={`automationpost post fade-in`}
      >
        <div className="profpic34"></div>
        <h1 className="profname2">Sinhala Bot</h1>
        <div className="postbarrier2">
          <img
            src={memoizedImages[currentIndexAutomation].src}
            alt={`Bot ${currentIndexAutomation + 1}`}
            className={memoizedImages[currentIndexAutomation].className}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(AutomationPost);
