import React, { memo } from 'react';
import './Experience.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ArtPost = ({ images, currentIndex }) => {
  return (
    <div
      className="artphone"
      alt="iphone8"
      data-tooltip-id="artphone-tooltip"
      data-tooltip-content="iPhone"
    >
      <ReactTooltip
        id="artphone-tooltip"
        effect="solid"
        place="top"
        className="custom-tooltip"
        delayShow={100}
        delayHide={100}
        arrowColor="rgba(31, 22, 83, 0.7)"
      />
      <div className="phonebtn1"></div>
      <div className="phonespeaker1"></div>
      <div className="phonecamera1"></div>

      {/* Just Posted Animation */}
      <div className="justposted">
        <p>Nisal Just Posted an Artwork +</p>
      </div>

      {/* Artwork Post */}
      <div key={currentIndex} className={`artpost post fade-in`}>
        <div className="profpic"></div>
        <h1 className="profname">Nisal Herath</h1>
        <div className="postbarrier">
          <img
            src={images[currentIndex].src}
            alt={`Art ${currentIndex + 1}`}
            className={images[currentIndex].className}
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ArtPost, (prevProps, nextProps) => {
  return (
    prevProps.currentIndex === nextProps.currentIndex &&
    prevProps.images === nextProps.images
  );
});
