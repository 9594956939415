import React, { useRef } from "react";
import { LazyMotion, domAnimation, useInView } from "framer-motion";
import "./Projects.css";
import "react-tooltip/dist/react-tooltip.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import github from "../Assets/github.svg";
import discord from "../Assets/discord.svg";
import browse from "../Assets/browse.svg";

// Project Card component with Fun-style animation improvements
const ProjectCard = ({ project, index }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.1 });
  
  // Stagger the animation delay based on item index
  const delay = index * 0.1;
  
  return (
    <div 
      ref={ref}
      className="project-card card"
      style={{
        transform: isInView ? "translateY(0)" : "translateY(20px)",
        opacity: isInView ? 1 : 0,
        transition: `opacity 0.6s ease-out ${delay}s, transform 0.6s ease-out ${delay}s`
      }}
    >
      <div className="project">
        <div className="projectstext">
          <h1 className="projectheader">{project.title}</h1>
          <div className="project-tags">
            {project.tags.map((tag, index) => (
              <span key={index} className={`label ${tag.className}`}>{tag.text}</span>
            ))}
          </div>
          {project.description}
        </div>
        <div className="button-container">
          {project.links.map((link, index) => (
            link.isLink ? (
              <Link
                key={index}
                to={link.url}
                className="githubbtn githubbtn1"
                aria-label={link.ariaLabel}
              >
                <img
                  src={link.icon}
                  className="githubimg"
                  alt={link.alt}
                  onError={(e) => (e.target.style.display = "none")}
                />
                {link.text}
              </Link>
            ) : (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label={link.ariaLabel}
                style={{ textDecoration: "none" }}
              >
                <img src={link.icon} className="githubimg" alt={link.alt} />
                {link.text}
              </a>
            )
          ))}
        </div>
      </div>
    </div>
  );
};

const Projects = () => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true, amount: 0.1 });

  // Project data
  const projects = [
    {
      title: "Food Fest Game",
      tags: [
        { text: "Fun", className: "fun" },
        { text: "Engaging", className: "engaging" },
        { text: "Compatible", className: "compatible" },
        { text: "Interactive", className: "interactive" }
      ],
      description: "A fun and interactive React-based game developed for a university food festival. The game lets players compete by scoring points in a Doodle Jump-style game. Firebase backend ensures real-time scoring and leaderboards.",
      links: [
        {
          isLink: false,
          url: "https://github.com/nisalherath/foodfestgame",
          icon: github,
          alt: "GitHub Logo",
          text: "Source Code",
          ariaLabel: "View Source Code on GitHub"
        },
        {
          isLink: false,
          url: "https://game.nisal.lk/",
          icon: browse,
          alt: "Play Game",
          text: "Play The Game",
          ariaLabel: "Play Food Fest Game"
        }
      ]
    },
    {
      title: "Nerdcake Bot",
      tags: [
        { text: "Versatile", className: "versatile" },
        { text: "Useful", className: "useful" },
        { text: "Fast", className: "fast" },
        { text: "Messages", className: "messages" },
        { text: "Reliable", className: "reliable" },
        { text: "24-7 / 365", className: "tag24-7" }
      ],
      description: "A multipurpose Discord bot developed with Python. It automates tasks like fetching random images, providing game deals, delivering positive quotes, and running speed tests to enhance user experience in Discord servers.",
      links: [
        {
          isLink: false,
          url: "https://discord.com/oauth2/authorize?client_id=1288790078826479646",
          icon: discord,
          alt: "Discord",
          text: "Try it on Discord",
          ariaLabel: "Try it on Discord"
        },
        {
          isLink: true,
          url: "/nerdcake",
          icon: browse,
          alt: "Navigate to Nerdcake details",
          text: "Read More",
          ariaLabel: "Read More about Nerdcake"
        }
      ]
    },
    {
      title: "Car Rental App",
      tags: [
        { text: "Minimal UI", className: "minimalui" },
        { text: "User-friendly", className: "userfriendly" },
        { text: "Efficient", className: "efficient" },
        { text: "Multi-Task", className: "multitask" },
        { text: "Reliable", className: "reliable" }
      ],
      description: "A full-fledged React-based rental management application with a Java Springboot backend. It allows users to easily view available vehicles, rent them quickly, and efficiently manage their rental periods. ",
      links: [
        {
          isLink: false,
          url: "https://github.com/nisalherath/RentalApp.git",
          icon: github,
          alt: "GitHub",
          text: "Source Code",
          ariaLabel: "View Source Code on GitHub"
        },
        {
          isLink: false,
          url: "https://rental.nisal.lk/",
          icon: browse,
          alt: "Browse App",
          text: "Try the App",
          ariaLabel: "Try the Rental App"
        }
      ]
    },
    {
      title: "Social Media Bot",
      tags: [
        { text: "Versatile", className: "versatile" },
        { text: "Useful", className: "useful" },
        { text: "Fast", className: "fast" },
        { text: "Messages", className: "messages" },
        { text: "Reliable", className: "reliable" },
        { text: "24-7 / 365", className: "tag24-7" }
      ],
      description: "A Python-based bot that automates social media posts by generating a random Sinhala word, turning it into an image, and posting it to Facebook, Instagram, and Twitter every hour.",
      links: [
        {
          isLink: false,
          url: "https://web.facebook.com/RandomSinhalaWord",
          icon: browse,
          alt: "Facebook",
          text: "On Facebook",
          ariaLabel: "Visit on Facebook"
        },
        {
          isLink: false,
          url: "https://www.instagracom/sinhala333/",
          icon: browse,
          alt: "Instagram",
          text: "On Instagram",
          ariaLabel: "Visit on Instagram"
        },
        {
          isLink: false,
          url: "https://x.com/sinhala333",
          icon: browse,
          alt: "Twitter",
          text: "On Twitter",
          ariaLabel: "Visit on Twitter"
        }
      ]
    },
    {
      title: "Deep Learning - Cat Detection",
      tags: [
        { text: "Automation", className: "versatile" },
        { text: "Machine Learning", className: "useful" },
        { text: "Fast", className: "fast" },
        { text: "Detection", className: "messages" },
        { text: "YOLOv5", className: "reliable" },
        { text: "ResNet50", className: "tag24-7" }
      ],
      description: "This Python project uses AI-powered image recognition to accurately detect and classify cats in real-time, automating the process with high precision.",
      links: [
        {
          isLink: false,
          url: "https://github.com/nisalherath/Kitty-Detect",
          icon: github,
          alt: "GitHub",
          text: "Source Code",
          ariaLabel: "View Source Code on GitHub"
        },
        {
          isLink: true,
          url: "/kitty-detect",
          icon: browse,
          alt: "Navigate to Nerdcake details",
          text: "Read More",
          ariaLabel: "Read More about Nerdcake"
        }
      ]
    },
    {
      title: "Sinhala Word API",
      tags: [
        { text: "API", className: "useful" },
        { text: "FastAPI", className: "fast" },
        { text: "Endpoints", className: "messages" },
        { text: "Generate", className: "reliable" },
        { text: "CORS", className: "tag24-7" },
        { text: "https", className: "versatile" }
      ],
      description: "This custom API generates random 3-letter Sinhala words, converts them into images, and provides direct access. Built with Python and FastAPI, it includes rate limiting for smooth performance and spam prevention.",
      links: [
        {
          isLink: false,
          url: "https://github.com/nisalherath/SinhalaImageAPI",
          icon: github,
          alt: "GitHub",
          text: "API Documentation",
          ariaLabel: "View Source Code on GitHub"
        },
        {
          isLink: true,
          url: "/blog/2",
          icon: browse,
          alt: "Navigate to Blog Post",
          text: "Blog Post",
          ariaLabel: "Read More The API"
        }
      ]
    }
  ];

  return (
    <div className="projects-main">
      <Helmet>
        <meta
          name="description"
          content="Explore my portfolio of projects including web applications, Discord bots, machine learning models, and more."
        />
        <meta
          name="keywords"
          content="React Projects, Discord Bot, Food Fest Game, Car Rental App, Machine Learning, API, Nisal Herath"
        />
        <meta property="og:title" content="Projects - Nisal Herath" />
        <meta
          property="og:description"
          content="Discover my portfolio of innovative web applications, bots, and machine learning projects."
        />
        <meta property="og:url" content="https://nisal.lk/projects" />
        <meta property="og:type" content="website" />
      </Helmet>

      <LazyMotion features={domAnimation}>
        <section 
          id="projects" 
          ref={containerRef}
          className="projects-section"
          style={{
            opacity: isInView ? 1 : 0,
            transform: isInView ? 'translateY(0)' : 'translateY(50px)',
            transition: 'opacity 0.8s ease-out, transform 0.8s ease-out'
          }}
        >
          <h1 className="projectsheader2">Some Projects</h1>

          <div className="project-flex">
            {projects.map((project, index) => (
              <ProjectCard key={index} project={project} index={index} />
            ))}
          </div>
        </section>
      </LazyMotion>
    </div>
  );
};

export default Projects;