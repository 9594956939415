import { useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Lenis from "@studio-freight/lenis";

const LenisProvider = ({ children }) => {
  const lenisRef = useRef(null);
  const location = useLocation();
  const rafId = useRef(null);

  useLayoutEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smooth: true,
      smoothTouch: false, // Disable smooth touch to prevent extra movements
      smoothWheel: true,
      wheelMultiplier: 1.0, // Adjust wheel multiplier for better control
      lerp: 0.1, // Lower value for more precise stopping
      infinite: false, // Ensure scrolling has a definite end
      gestureOrientation: "vertical",
      orientation: "vertical",
      touchMultiplier: 1.0,
      syncTouch: false, // Don't synchronize touch events
      syncTouchLerp: 0.1, // Lower value for more precise stopping with touch
    });

    // Add listener to stop completely once scrolling ends
    lenis.on('scroll', ({ velocity }) => {
      if (Math.abs(velocity) < 0.001) {
        lenis.stop();
        setTimeout(() => lenis.start(), 30); // Short pause before restarting
      }
    });

    lenisRef.current = lenis;

    const raf = (time) => {
      lenis.raf(time);
      rafId.current = requestAnimationFrame(raf);
    };

    rafId.current = requestAnimationFrame(raf);

    return () => {
      cancelAnimationFrame(rafId.current);
      lenis.destroy();
    };
  }, []);

  useLayoutEffect(() => {
    if (lenisRef.current) {
      lenisRef.current.stop();
      lenisRef.current.scrollTo(0, { immediate: true });
      setTimeout(() => {
        lenisRef.current.start();
      }, 50); // Add small delay before restarting to prevent residual momentum
    }
  }, [location]);

  return children;
};

export default LenisProvider;