import React, { memo, useRef, useCallback } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { EffectCoverflow, Autoplay } from 'swiper/modules';

const FBcarousel = ({ posts }) => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);

  const onAutoplayTimeLeft = useCallback((swiper, time, progress) => {
    requestAnimationFrame(() => {
      if (progressCircle.current) {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
      }
      if (progressContent.current) {
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
      }
    });
  }, []);

  return (
    <>
      <Swiper
        effect="coverflow"
        grabCursor={true}
        centeredSlides={true}
        slidesPerView="auto"
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
          waitForTransition: false,
          enabled: true,
        }}
        modules={[EffectCoverflow, Autoplay]}
        className="mySwiper"
        onAutoplayTimeLeft={onAutoplayTimeLeft}
        style={{
          width: '100%',
          height: '100%',
          outline: 'none',
          minWidth: '300px',
        }}
      >
        {posts.map((post, index) => (
          <SwiperSlide key={index} style={{ outline: 'none' }}>
            <div
              style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                overflow: 'hidden',
                borderRadius: '0px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                loading="lazy"
                src={post.full_picture || 'https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot1_xk8ffb.webp'}
                alt={post.message || `Slide ${index + 1}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                  borderRadius: '0px',
                  outline: 'none',
                }}
              />
            </div>
          </SwiperSlide>
        ))}

        <div className="autoplay-progress" slot="container-end">
          <svg viewBox="0 0 48 48" ref={progressCircle}>
            <circle cx="24" cy="24" r="20"></circle>
          </svg>
          <span ref={progressContent} className="pagecounter"></span>
        </div>
      </Swiper>
    </>
  );
};


export default memo(FBcarousel, (prevProps, nextProps) => {
  return prevProps.posts.length === nextProps.posts.length &&
    prevProps.posts.every((post, i) => post.full_picture === nextProps.posts[i].full_picture && post.message === nextProps.posts[i].message);
});
