import React, { useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';
import Navbar from '../Navbar/Navbar';
import About from '../About/About';
import Experience from '../Experience/Experience';
import Skills from '../Skills/Skills';
import Projects from '../Projects/Projects';
import Footer from '../Footer/Footer';
import Fun from '../Fun/Fun';
import './HomePage.css';
import 'animate.css';

const HomePage = () => {
  const sectionsRef = useRef({});

  useEffect(() => {
    const expirationTime = 60 * 60 * 1000; // 1 Hour
    const currentTime = new Date().getTime();
    const blogNotificationData = JSON.parse(localStorage.getItem("blogNotificationData"));
  
    if (!blogNotificationData || currentTime - blogNotificationData.timestamp > expirationTime) {
      Swal.fire({
        title: "Check out my latest blog!",
        text: "Stay updated with my latest posts and insights.",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Take me there",
        cancelButtonText: "Later",
        customClass: {
          backdrop: 'alert-backdrop',
          popup: 'alert',
          confirmButton: 'alert-btn',
          cancelButton: 'alert-btn',
        },
        focusConfirm: false,
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = "/blog";
        } else {
          localStorage.setItem(
            "blogNotificationData",
            JSON.stringify({ token: "true", timestamp: currentTime })
          );
        }
      });
    }
  }, []);
  

  // Manage intersection observer for URL hash updates
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    sections.forEach((section) => {
      sectionsRef.current[section.id] = section;
    });

    let lastUrlUpdate = Date.now();
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const now = Date.now();
            if (now - lastUrlUpdate > 300) { // Throttle hash updates
              const sectionId = entry.target.id;
              window.history.pushState(null, '', `#${sectionId}`);
              lastUrlUpdate = now;
            }
          }
        });
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '0px 0px -20% 0px',
      }
    );

    sections.forEach((section) => observer.observe(section));

    return () => {
      sections.forEach((section) => observer.unobserve(section));
    };
  }, []);

  return (
    <div>
      {/* Helmet for SEO */}
      <Helmet>
  {/* Primary Meta Tags */}
  <title>Nisal Herath - Home</title>
  <meta name="description" content="Experienced Web Developer specializing in React.js, frontend development, Firebase, and API integrations. Explore my portfolio, projects, and insights into modern web technologies." />
  <meta name="keywords" content="Nisal Herath, Web Developer, Frontend Developer, React.js, Firebase, API integrations, Portfolio, CSS Tips, Nerdcake Bot, JavaScript, UI/UX, Automation, Python Bots" />
  <meta name="author" content="Nisal Herath" />
  <meta name="robots" content="index, follow" />
  <link rel="canonical" href="https://nisal.lk/" />

  {/* Open Graph Meta Tags (for social media sharing) */}
  <meta property="og:title" content="Nisal Herath | Web Developer & Automation Engineer" />
  <meta property="og:description" content="Frontend Developer skilled in React.js, Firebase, and UI/UX. Explore my projects, articles, and automation tools." />
  <meta property="og:image" content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1741035854/3653cd860f023f18ded494b801b534d4esdgfghg_gswhk3.webp" />
  <meta property="og:url" content="https://nisal.lk/" />
  <meta property="og:type" content="website" />

  {/* Twitter Card Meta Tags */}
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Nisal Herath | Web Developer & Automation Engineer" />
  <meta name="twitter:description" content="React.js specialist and frontend developer. Check out my portfolio, blog, and automation projects." />
  <meta name="twitter:image" content="https://res.cloudinary.com/dlnhogbjy/image/upload/v1741035854/3653cd860f023f18ded494b801b534d4esdgfghg_gswhk3.webp" />
  <meta name="twitter:site" content="@artnisal" />

  {/* Structured Data (Schema.org) */}
  <script type="application/ld+json">
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Person",
      "name": "Nisal Herath",
      "url": "https://nisal.lk",
      "sameAs": [
        "https://linkedin.com/in/nisal-herath",
        "https://github.com/nisalherath",
        "https://x.com/ArtNisal",
        "https://web.facebook.com/mooncaque",
        "https://instagram.com/mooncaque"
      ],
      "jobTitle": "Web Developer & Automation Engineer",
      "description": "React.js and frontend developer specializing in UI/UX, Firebase, and automation tools.",
      "image": "https://res.cloudinary.com/dlnhogbjy/image/upload/v1741035854/3653cd860f023f18ded494b801b534d4esdgfghg_gswhk3.webp"
    })}
  </script>
</Helmet>

      <Navbar />
      <main>
        <section id="about">
          <About />
        </section>
        <section id="experience">
          <Experience />
        </section>
        <section id="skills">
          <Skills />
        </section>
        <section id="projects">
          <Projects />
        </section>
        <section id="fun">
          <Fun />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
