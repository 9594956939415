import * as React from "react";
const ShapeTool = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={357.4}
    height={355.19}
    viewBox="0 0 357.4 355.19"
    className="shape-tool"
    {...props}
  >
    <title>{"shape-tool"}</title>
    <g id="Rectangle">
      <g>
        <rect
          x={1.5}
          y={1.5}
          width={319.84}
          height={319.84}
          style={{
            fill: "#fff",
          }}
        />
        <g>
          <rect
            x={1.5}
            y={1.5}
            width={319.84}
            height={319.84}
            style={{
              fill: "none",
              stroke: "#000",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <line
            x1={355.9}
            y1={330.5}
            x2={337.56}
            y2={330.5}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <line
            x1={332.36}
            y1={353.69}
            x2={332.36}
            y2={335.35}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <line
            x1={309.34}
            y1={330.5}
            x2={327.68}
            y2={330.5}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
          <line
            x1={332.36}
            y1={306.87}
            x2={332.36}
            y2={325.22}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeMiterlimit: 10,
              strokeWidth: 3,
            }}
          />
        </g>
      </g>
    </g>
    <g id="Star">
      <g>
        <polygon
          points="257.24 304.28 161.45 234.75 65.72 304.37 102.25 191.78 6.45 122.25 124.82 122.19 161.35 9.6 197.97 122.16 316.34 122.11 220.61 191.72 257.24 304.28"
          style={{
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <line
          x1={355.9}
          y1={330.5}
          x2={337.56}
          y2={330.5}
          style={{
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <line
          x1={332.36}
          y1={353.69}
          x2={332.36}
          y2={335.35}
          style={{
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <line
          x1={309.34}
          y1={330.5}
          x2={327.68}
          y2={330.5}
          style={{
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
        <line
          x1={332.36}
          y1={306.87}
          x2={332.36}
          y2={325.22}
          style={{
            fill: "none",
            stroke: "#000",
            strokeLinecap: "round",
            strokeMiterlimit: 10,
            strokeWidth: 3,
          }}
        />
      </g>
    </g>
  </svg>
);
export default ShapeTool;
