import React, { useState, useEffect, useCallback, memo, useMemo } from "react";
import { LazyMotion, domAnimation, m } from "framer-motion";
import "./Experience.css";
import ArtPost from "./ArtPost";
import AutomationPost from "./AutomationPost";
import EverydayPost from "./EverydayPost";
import "react-tooltip/dist/react-tooltip.css";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import browse from "../Assets/browse.svg";
import discord from "../Assets/discord.svg";

import NFTcarousel from "./Carousels/NFTcarousel";
import FBcarousel from "./Carousels/FBcarousel";
import Unsplashcarousel from "./Carousels/Unsplashcarousel";

import UnsplashLogo from "../Assets/unsplash.svg";
import MetaLogo from "../Assets/fb.svg";

import python from "../Assets/py.svg";

// Move constants outside component to prevent re-creation on each render
const images = [
  {
    className: "pic1",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563562/Milky_ysozae.webp",
  },
  {
    className: "pic2",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563517/Christmas_2022_o0naoa.webp",
  },
  {
    className: "pic3",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563543/GOT_fa06f5.webp",
  },
  {
    className: "pic4",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563553/Cricket_thzzxm.webp",
  },
  {
    className: "pic5",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563602/Icey_night_km1nlu.webp",
  },
  {
    className: "pic6",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563533/Christmas_2021_xri6zw.webp",
  },
];

const pictures = [
  {
    className: "picture1",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563806/cow2_wwz9pl.webp",
    alt: "Art Image",
  },
  {
    className: "picture2",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563806/cow3_vpnlcw.webp",
    alt: "Art Image",
  },
  {
    className: "picture3",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563807/cub3_dchy0n.webp",
    alt: "Art Image",
  },
  {
    className: "picture4",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563806/cub2_uetxts.webp",
    alt: "Art Image",
  },
  {
    className: "picture5",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563807/spork3_ldanjt.webp",
    alt: "Art Image",
  },
  {
    className: "picture6",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734563807/spork1_tp5v96.webp",
    alt: "Art Image",
  },
];

const automationImages = [
  {
    className: "bot1",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot1_xk8ffb.webp",
  },
  {
    className: "bot2",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot2_h6oxev.webp",
  },
  {
    className: "bot3",
    src: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734564021/bot4_lwdyqs.webp",
  },
];

const messages = [
  {
    text: "/IP",
    reply: "IP Address:📫\n 103.21.166.251 \n Location:📍\n Colombo \n ZIP Code:✉️ \n 00100 ",
  },
  {
    text: "/Wallpapers",
    reply: "Here is a Wallpaper!🌅",
    replyimg: "https://res.cloudinary.com/dlnhogbjy/image/upload/v1734591096/Poooos_ndn8sd.webp",
  },
  { text: "/Gamedeals", reply: "No Gamedeals for this Week!☹️" },
];

const everydayphone = {
  alt: "iPhone",
};

// Extract animation variants as constants
const variants = {
  enter: { opacity: 0, y: 100, position: "absolute" },
  center: { opacity: 1, y: 0, position: "absolute" },
  exit: { opacity: 0, y: -100, position: "absolute" },
};

const justPostedVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { duration: 0.6, ease: "easeOut" },
  },
};

const fadeInOptimized = {
  initial: { opacity: 0, y: 50 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true, amount: 0.1 },
  transition: { duration: 0.8, ease: "easeOut" },
};

// Create memoized components to prevent unnecessary re-renders
const MemoizedArtPost = memo(ArtPost);
const MemoizedNFTCarousel = memo(NFTcarousel);
const MemoizedAutomationPost = memo(AutomationPost);
const MemoizedFBCarousel = memo(FBcarousel);
const MemoizedEverydayPost = memo(EverydayPost);
const MemoizedUnsplashCarousel = memo(Unsplashcarousel);

// Extract section components for better code organization and performance
const ArtSection = memo(({ currentIndex }) => (
  <m.div className="expdiv" {...fadeInOptimized}>
    <div className="art">
      <div className="exp-flex-column2">
        <h1 className="exptext">Digital Art</h1>

        <div className="arttext">
          <div className="arttext-content">
            <h1 className="artheader">Digital Art</h1>
            With over 4 years of experience in vector art since 2020, I
            specialize in designing custom icons, logos, portraits,
            character designs, and NFT art. My creative process blends
            attention to detail and a passion for digital design to bring
            visually striking and unique concepts to life.
          </div>
        </div>

        <div className="arttext">
          <div className="arttext-content">
            <h1 className="artheader">Socials</h1>
            You can find my artworks here.
            <div className="button-container">
              <a
                href="https://web.facebook.com/mooncaque"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Visit on Facebook"
                style={{ textDecoration: "none" }}
              >
                <img src={browse} className="githubimg" alt="Facebook" />
                On Facebook
              </a>

              <a
                href="https://www.instagram.com/mooncaque/"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Visit on Instagram"
                style={{ textDecoration: "none" }}
              >
                <img src={browse} className="githubimg" alt="Instagram" />
                On Instagram
              </a>

              <a
                href="https://www.behance.net/nisalherath"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Visit on Behance"
                style={{ textDecoration: "none" }}
              >
                <img src={browse} className="githubimg" alt="Behance" />
                On Behance
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="exp-flex-column1">
        <div className="exp-box">
          <div className="box1">
            <MemoizedArtPost
              images={images}
              currentIndex={currentIndex}
              variants={variants}
              justPostedVariants={justPostedVariants}
            />
          </div>

          <div className="box2">
            <div className="box21">
              <MemoizedNFTCarousel pictures={pictures} />
            </div>

            <div className="box22">
              <p>
                "" I really love digital art and creating vector designs.
                It's amazing how a simple idea can turn into something
                beautiful with creativity. Each project is a chance for me
                to try new things and share my ideas through art. ""
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </m.div>
));

const AutomationSection = memo(({ currentIndexAutomation, posts, loading }) => (
  <m.div className="expdiv" {...fadeInOptimized}>
    <div className="automation">
      <div className="exp-flex-column2">
        <h1 className="exptext">Automation</h1>
        <div className="automationtext">
          <div className="pythonimg">
            <img src={python} alt="pythonimg" />
          </div>

          <div className="automationtext-content">
            <h1 className="automationheader">Automation</h1>I specialize in
            creating Python-based bots and automation solutions that
            simplify processes and increase productivity. I focus on
            developing web applications with React and automating tasks such
            as social media posts and other repetitive activities to save
            time and improve efficiency. My approach combines technical
            expertise with a passion for optimizing workflows through
            automation.
          </div>
        </div>

        <div className="automationtext">
          <div className="automationtext-content">
            <h1 className="automationheader">Socials</h1>
            You can find this Bot on these socials.
            <div className="button-container">
              <a
                href="https://web.facebook.com/RandomSinhalaWord"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Visit on Facebook"
                style={{ textDecoration: "none" }}
              >
                <img src={browse} className="githubimg" alt="Facebook" />
                On Facebook
              </a>

              <a
                href="https://www.instagram.com/sinhala333/"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Visit on Instagram"
                style={{ textDecoration: "none" }}
              >
                <img src={browse} className="githubimg" alt="Instagram" />
                On Instagram
              </a>

              <a
                href="https://x.com/sinhala333"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Visit on Twitter"
                style={{ textDecoration: "none" }}
              >
                <img src={browse} className="githubimg" alt="Twitter" />
                On Twitter
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="exp-flex-column1">
        <div className="exp-box">
          <div className="box1">
            <MemoizedAutomationPost
              automationImages={automationImages}
              currentIndexAutomation={currentIndexAutomation}
              variants={variants}
              justPostedVariants={justPostedVariants}
            />
          </div>

          <div className="box2">
            <p className="latest">
              Latest Posts on
              <img src={MetaLogo} className="metalogo" alt="logo" />
            </p>

            <div>
              <div className="box21">
                <div className="poweredby1">
                  <p>Powered By Meta API</p>
                  <img src={MetaLogo} className="metalogo" alt="logo" />
                </div>

                <div className="facebook-carousel">
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                      }}
                    >
                      <Skeleton height={300} width={300} />
                    </div>
                  ) : (
                    <MemoizedFBCarousel posts={posts} />
                  )}
                </div>
              </div>
            </div>

            <div className="box22">
              <p>
                "" I enjoy automating tasks with the bots I create. It's
                exciting to see how something repetitive can be done
                automatically, saving time and making life easier. I love
                finding ways to make everyday tasks simpler and more
                efficient through automation, and you can see the latest
                post made by the bot on Facebook. ""
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </m.div>
));

const EverydaySection = memo(({ currentMessage, showReply, showImage, animals, isLoading, fetchStatus, fetchUnsplashImages }) => (
  <m.div className="expdiv" {...fadeInOptimized}>
    <div className="everyday">
      <div className="exp-flex-column2">
        <h1 className="exptext">Everyday Life</h1>

        <div className="everydaytext">
          <div className="everydaytext-content">
            <h1 className="everydayheader">Everyday Life</h1>I develop
            utility bots that automate everyday tasks to improve user
            experience, using APIs to streamline processes. One bot, for
            example, streamlines Discord interactions by automating features
            like fetching images, providing game deals, delivering quotes,
            and running speed tests.
          </div>
        </div>

        <div className="everydaytext">
          <div className="everydaytext-content">
            <h1 className="everydayheader">On Discord</h1>
            You can try out one of these bots and their functionalities for
            Free on Discord.
            <div className="button-container">
              <a
                href="https://discord.com/oauth2/authorize?client_id=1288790078826479646"
                target="_blank"
                rel="noopener noreferrer"
                className="githubbtn githubbtn1"
                aria-label="Try it on Discord"
                style={{ textDecoration: "none" }}
              >
                <img src={discord} className="githubimg" alt="Discord" />
                Try it on Discord
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="exp-flex-column1">
        <div className="exp-box">
          <div className="box1">
            <MemoizedEverydayPost
              everydayphone={everydayphone}
              currentMessage={currentMessage}
              messages={messages}
              showReply={showReply}
              showImage={showImage}
            />
          </div>

          <div className="box2">
            <div>
              <div className="box21">
                <div className="poweredby2">
                  <p>Powered By Unsplash</p>
                  <img
                    src={UnsplashLogo}
                    className="unsplashlogo"
                    alt="unsplash"
                  />
                </div>

                <div className="animal-carousel">
                  {animals.length === 0 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "300px",
                        width: "300px",
                      }}
                    >
                      <Skeleton height={300} width={300} />
                    </div>
                  ) : (
                    <MemoizedUnsplashCarousel images={animals} />
                  )}
                </div>
              </div>
            </div>

            <button
              onClick={fetchUnsplashImages}
              disabled={isLoading}
              className="read-more3"
            >
              {isLoading
                ? "Loading ⏳"
                : fetchStatus === "success"
                  ? "Success✨"
                  : "Fetch New Images using API"}
            </button>

            <div className="box22">
              <p>
                "" I use my software engineering knowledge to make everyday
                tech tasks easier. It's rewarding to create solutions that
                simplify complex processes and help people save time. You
                can see me working with APIs, like fetching new images from
                Unsplash, to turn technical challenges into simple,
                user-friendly tools. ""
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </m.div>
));

const Experience = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexAutomation, setCurrentIndexAutomation] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(0);
  const [showReply, setShowReply] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [animals, setAnimals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fetchStatus, setFetchStatus] = useState("");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // Memoize fetch functions to prevent recreation on each render
  const fetchPosts = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axios.get("/.netlify/functions/fetchFbPost");
      setPosts(response.data);
    } catch (error) {
      console.error("Error fetching Facebook posts:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchUnsplashImages = useCallback(() => {
    setIsLoading(true);
    setFetchStatus("");

    axios
      .get("/.netlify/functions/fetchImages")
      .then((response) => {
        setAnimals(response.data);
        setIsLoading(false);
        setFetchStatus("success");
        setTimeout(() => setFetchStatus(""), 1000);
      })
      .catch((error) => {
        setIsLoading(false);
        setFetchStatus("error");
        console.error("Error fetching data:", error);
      });
  }, []);

  // Cleanup function to prevent memory leaks
  useEffect(() => {
    fetchPosts();
    fetchUnsplashImages();
  }, [fetchPosts, fetchUnsplashImages]);

  // Optimize image cycling with a single effect
  useEffect(() => {
    let messageInterval, imageInterval, automationInterval, imageTimeout;

    // Handle message cycling
    if (messages.length > 0) {
      messageInterval = setInterval(() => {
        setCurrentMessage((prev) => (prev + 1 <= messages.length ? prev + 1 : 1));
        setShowReply(false);
        setTimeout(() => setShowReply(true), 1500);
      }, 3000);
    }

    imageInterval = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % images.length);
    }, 3000);

    automationInterval = setInterval(() => {
      setCurrentIndexAutomation((prev) => (prev + 1) % automationImages.length);
    }, 3000);

    if (currentMessage > 0 && showReply) {
      setShowImage(false);
      imageTimeout = setTimeout(() => setShowImage(true), 500);
    }

    // Cleanup all intervals and timeouts
    return () => {
      clearInterval(messageInterval);
      clearInterval(imageInterval);
      clearInterval(automationInterval);
      clearTimeout(imageTimeout);
    };
  }, [currentMessage, showReply]);

  // Using LazyMotion with useMemo to prevent unnecessary rerenders
  const animationFeatures = useMemo(() => domAnimation, []);

  return (
    <section id="experience" className="experience-section">
      <LazyMotion features={animationFeatures}>
        <ArtSection currentIndex={currentIndex} />
        <AutomationSection 
          currentIndexAutomation={currentIndexAutomation}
          posts={posts}
          loading={loading}
        />
        <EverydaySection
          currentMessage={currentMessage}
          showReply={showReply}
          showImage={showImage}
          animals={animals}
          isLoading={isLoading}
          fetchStatus={fetchStatus}
          fetchUnsplashImages={fetchUnsplashImages}
        />
      </LazyMotion>
    </section>
  );
};

export default Experience;