import * as React from "react";
const Windmill = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={441.72}
    height={456.91}
    viewBox="0 0 441.72 456.91"
    className="windy"
    {...props}
  >
    <title>{"milly"}</title>
    <g id="mill" className="mill">
      <path
        d="M385.33,473.68a9.3,9.3,0,0,1-8.77,12.37H123.44a9.3,9.3,0,0,1-8.77-12.37c46.62-133.21,48.07-256.93,48.08-258.16a87.25,87.25,0,0,1,174.5,0C337.26,216.75,338.63,340.26,385.33,473.68Z"
        transform="translate(-29.14 -29.14)"
        style={{
          fill: "#020202",
        }}
      />
    </g>
    <g id="mill-wing" className="mill-wing">
      <g>
        <g>
          <polygon
            points="424.04 188.79 440.22 220.86 401.79 220.86 388.16 194.02 424.04 188.79"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M469.36,250l-16.18,22.11-187.43-9.79A19.91,19.91,0,0,0,270,250H469.36Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="409.5 160 424.04 188.79 388.16 194.02 375.42 168.93 409.5 160"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="396.92 135.08 409.5 160 375.42 168.93 363.59 145.68 396.92 135.08"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="388.16 194.02 401.79 220.86 368.17 220.86 356.89 198.59 388.16 194.02"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="375.42 168.93 388.16 194.02 356.89 198.59 345.82 176.7 375.42 168.93"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="363.59 145.68 375.42 168.93 345.82 176.7 334.77 154.84 363.59 145.68"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="356.89 198.59 368.17 220.86 336.56 220.86 328.48 202.73 356.89 198.59"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="345.82 176.7 356.89 198.59 328.48 202.73 319.92 183.5 345.82 176.7"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="334.77 154.84 345.82 176.7 319.92 183.5 310.58 162.53 334.77 154.84"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="328.48 202.73 336.56 220.86 313.87 220.86 306.92 205.87 328.48 202.73"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="319.92 183.5 328.48 202.73 306.92 205.87 299.06 188.96 319.92 183.5"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="310.58 162.53 319.92 183.5 299.06 188.96 289.85 169.13 310.58 162.53"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="306.92 205.87 313.87 220.86 294.42 220.86 288.62 208.54 306.92 205.87"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="299.06 188.96 306.92 205.87 288.62 208.54 281.55 193.55 299.06 188.96"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="296.04 363.6 306.63 396.94 281.72 409.5 272.79 375.42 296.04 363.6"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="289.85 169.13 299.06 188.96 281.55 193.55 272.61 174.61 289.85 169.13"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="286.88 334.77 296.04 363.6 272.79 375.42 265.02 345.82 286.88 334.77"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="279.18 310.58 286.88 334.77 265.02 345.82 258.22 319.92 279.18 310.58"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="272.79 375.42 281.72 409.5 252.92 424.04 247.7 388.16 272.79 375.42"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="272.59 289.85 279.18 310.58 258.22 319.92 252.76 299.06 272.59 289.85"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="265.02 345.82 272.79 375.42 247.7 388.16 243.13 356.9 265.02 345.82"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="267.11 272.63 272.59 289.85 252.76 299.06 248.17 281.56 267.11 272.63"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="258.22 319.92 265.02 345.82 243.13 356.9 238.99 328.49 258.22 319.92"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="252.76 299.06 258.22 319.92 238.99 328.49 235.84 306.92 252.76 299.06"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="247.7 388.16 252.92 424.04 220.86 440.22 220.86 401.79 247.7 388.16"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="248.17 281.56 252.76 299.06 235.84 306.92 233.17 288.62 248.17 281.56"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="243.13 356.9 247.7 388.16 220.86 401.79 220.86 368.17 243.13 356.9"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="238.99 328.49 243.13 356.9 220.86 368.17 220.86 336.56 238.99 328.49"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="235.84 306.92 238.99 328.49 220.86 336.56 220.86 313.87 235.84 306.92"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M251.38,230c.21,0,.43,0,.64.06h.07l.86.11.17,0c.22,0,.43.06.65.11l.51.11a13.2,13.2,0,0,1,1.31.33c.36.1.7.22,1,.34a5.55,5.55,0,0,1,.64.24,2.18,2.18,0,0,1,.23.09,6.21,6.21,0,0,1,.66.28,20,20,0,0,1,4.16,2.52v0a20,20,0,1,1-29.88,6.21c.27-.5.56-1,.87-1.43.15-.23.3-.45.46-.67l.49-.65A19.91,19.91,0,0,1,250,230l.87,0Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="233.17 288.62 235.84 306.92 220.86 313.87 220.86 294.43 233.17 288.62"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M262.31,234.22a19.85,19.85,0,0,0-4.16-2.49,3.73,3.73,0,0,0-.66-.28,2.18,2.18,0,0,0-.23-.09,5.55,5.55,0,0,0-.64-.24c-.33-.12-.67-.24-1-.34a13.2,13.2,0,0,0-1.31-.33l-.51-.11c-.22-.05-.43-.08-.65-.11l-.17,0-.86-.11H252c-.21,0-.43-.05-.64-.06l-.51,0-.87,0V30.64l22.11,16.18Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M262.31,234.25a20,20,0,0,0-4.16-2.52,19.85,19.85,0,0,1,4.16,2.49Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M257.49,231.45a3.73,3.73,0,0,1,.66.28A6.21,6.21,0,0,0,257.49,231.45Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="220.86 127.85 220.86 147.29 208.54 153.1 205.87 134.8 220.86 127.85"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="220.86 105.16 220.86 127.85 205.87 134.8 202.73 113.23 220.86 105.16"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="220.86 73.55 220.86 105.16 202.73 113.23 198.58 84.82 220.86 73.55"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="220.86 39.93 220.86 73.55 198.58 84.82 194.02 53.56 220.86 39.93"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="220.86 1.5 220.86 39.93 194.02 53.56 188.79 17.68 220.86 1.5"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M250,270V469.36l-22.11-16.18,9.79-187.43A19.85,19.85,0,0,0,250,270Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="205.87 134.8 208.54 153.1 193.55 160.16 188.96 142.66 205.87 134.8"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="202.73 113.23 205.87 134.8 188.96 142.66 183.48 121.8 202.73 113.23"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M234.25,237.68l-.49.65A7.37,7.37,0,0,1,234.25,237.68Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M233.76,238.33c-.16.22-.31.44-.46.67C233.44,238.77,233.6,238.55,233.76,238.33Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <path
            d="M46.82,227.89l187.43,9.79a7.37,7.37,0,0,0-.49.65c-.16.22-.32.44-.46.67-.31.46-.6.93-.87,1.43A19.68,19.68,0,0,0,230,250H30.64Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="198.58 84.82 202.73 113.23 183.48 121.8 176.7 95.9 198.58 84.82"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="194.02 53.56 198.58 84.82 176.7 95.9 168.93 66.3 194.02 53.56"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="188.79 17.68 194.02 53.56 168.93 66.3 159.99 32.22 188.79 17.68"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="188.96 142.66 193.55 160.16 174.6 169.09 169.13 151.87 188.96 142.66"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="183.48 121.8 188.96 142.66 169.13 151.87 162.53 131.14 183.48 121.8"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="176.7 95.9 183.48 121.8 162.53 131.14 154.84 106.95 176.7 95.9"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="168.93 66.3 176.7 95.9 154.84 106.95 145.68 78.12 168.93 66.3"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="160.16 248.17 169.09 267.11 151.87 272.59 142.66 252.76 160.16 248.17"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="159.99 32.22 168.93 66.3 145.68 78.12 135.08 44.78 159.99 32.22"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="153.1 233.18 160.16 248.17 142.66 252.76 134.8 235.85 153.1 233.18"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="147.29 220.86 153.1 233.18 134.8 235.85 127.85 220.86 147.29 220.86"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="142.66 252.76 151.87 272.59 131.13 279.19 121.8 258.22 142.66 252.76"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="134.8 235.85 142.66 252.76 121.8 258.22 113.23 238.99 134.8 235.85"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="127.85 220.86 134.8 235.85 113.23 238.99 105.15 220.86 127.85 220.86"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="121.8 258.22 131.13 279.19 106.95 286.88 95.89 265.02 121.8 258.22"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="113.23 238.99 121.8 258.22 95.89 265.02 84.82 243.13 113.23 238.99"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="105.15 220.86 113.23 238.99 84.82 243.13 73.55 220.86 105.15 220.86"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="95.89 265.02 106.95 286.88 78.12 296.04 66.3 272.79 95.89 265.02"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="84.82 243.13 95.89 265.02 66.3 272.79 53.56 247.7 84.82 243.13"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="73.55 220.86 84.82 243.13 53.56 247.7 39.92 220.86 73.55 220.86"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="66.3 272.79 78.12 296.04 44.78 306.64 32.21 281.72 66.3 272.79"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="53.56 247.7 66.3 272.79 32.21 281.72 17.68 252.93 53.56 247.7"
            style={{
              fill: "#fff",
            }}
          />
          <polygon
            points="39.92 220.86 53.56 247.7 17.68 252.93 1.5 220.86 39.92 220.86"
            style={{
              fill: "#fff",
            }}
          />
        </g>
        <g>
          <path
            d="M234.25,237.68A19.91,19.91,0,0,1,250,230"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 4,
            }}
          />
          <path
            d="M262.31,234.22a20,20,0,1,1-29.88,6.21"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 4,
            }}
          />
          <line
            x1={188.79}
            y1={17.68}
            x2={159.99}
            y2={32.22}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={194.02}
            y1={53.56}
            x2={168.93}
            y2={66.3}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={198.58}
            y1={84.82}
            x2={176.7}
            y2={95.9}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={202.73}
            y1={113.23}
            x2={183.48}
            y2={121.8}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={205.87}
            y1={134.8}
            x2={188.96}
            y2={142.66}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={193.55}
            y1={160.16}
            x2={208.54}
            y2={153.1}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="168.93 66.3 159.99 32.22 135.08 44.78 145.68 78.12"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="176.7 95.9 168.93 66.3 145.68 78.12 154.84 106.95"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="183.48 121.8 176.7 95.9 154.84 106.95 162.53 131.14"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="188.96 142.66 183.48 121.8 162.53 131.14 169.13 151.87"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polygon
            points="169.13 151.87 174.6 169.09 193.55 160.16 188.96 142.66 169.13 151.87"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 1.5 188.79 17.68 194.02 53.56"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 39.93 194.02 53.56 198.58 84.82"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 73.55 198.58 84.82 202.73 113.23"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 105.16 202.73 113.23 205.87 134.8"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 127.85 205.87 134.8 208.54 153.1 220.86 147.29"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={252.92}
            y1={424.04}
            x2={281.72}
            y2={409.5}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={247.7}
            y1={388.16}
            x2={272.79}
            y2={375.42}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={243.13}
            y1={356.9}
            x2={265.02}
            y2={345.82}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={238.99}
            y1={328.49}
            x2={258.22}
            y2={319.92}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={235.84}
            y1={306.92}
            x2={252.76}
            y2={299.06}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={248.17}
            y1={281.56}
            x2={233.17}
            y2={288.62}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="272.79 375.42 281.72 409.5 306.63 396.94 296.04 363.6"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="265.02 345.82 272.79 375.42 296.04 363.6 286.88 334.77"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="258.22 319.92 265.02 345.82 286.88 334.77 279.18 310.58"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="252.76 299.06 258.22 319.92 279.18 310.58 272.59 289.85"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polygon
            points="272.59 289.85 267.11 272.63 248.17 281.56 252.76 299.06 272.59 289.85"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 440.22 252.92 424.04 247.7 388.16"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 401.79 247.7 388.16 243.13 356.9"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 368.17 243.13 356.9 238.99 328.49"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 336.56 238.99 328.49 235.84 306.92"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="220.86 313.87 235.84 306.92 233.17 288.62 220.86 294.43"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <path
            d="M262.31,234.22l9.8-187.4L250,30.64V230l.87,0,.51,0c.21,0,.43,0,.64.06h.07l.86.11.17,0c.22,0,.43.06.65.11l.51.11a13.2,13.2,0,0,1,1.31.33c.36.1.7.22,1,.34a5.55,5.55,0,0,1,.64.24,2.18,2.18,0,0,1,.23.09,6.21,6.21,0,0,1,.66.28,20,20,0,0,1,4.16,2.52Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <path
            d="M250,323.57V270a19.85,19.85,0,0,1-12.32-4.27l-9.79,187.43L250,469.36V323.57Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={17.68}
            y1={252.93}
            x2={32.21}
            y2={281.72}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={53.56}
            y1={247.7}
            x2={66.3}
            y2={272.79}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={84.82}
            y1={243.13}
            x2={95.89}
            y2={265.02}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={113.23}
            y1={238.99}
            x2={121.8}
            y2={258.22}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={134.8}
            y1={235.85}
            x2={142.66}
            y2={252.76}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={160.16}
            y1={248.17}
            x2={153.1}
            y2={233.18}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="66.3 272.79 32.21 281.72 44.78 306.64 78.12 296.04"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="95.89 265.02 66.3 272.79 78.12 296.04 106.95 286.88"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="121.8 258.22 95.89 265.02 106.95 286.88 131.13 279.19"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="142.66 252.76 121.8 258.22 131.13 279.19 151.87 272.59"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polygon
            points="151.87 272.59 169.09 267.11 160.16 248.17 142.66 252.76 151.87 272.59"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="1.5 220.86 17.68 252.93 53.56 247.7"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="39.92 220.86 53.56 247.7 84.82 243.13"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="73.55 220.86 84.82 243.13 113.23 238.99"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="105.15 220.86 113.23 238.99 134.8 235.85"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="127.85 220.86 134.8 235.85 153.1 233.18 147.29 220.86"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={424.04}
            y1={188.79}
            x2={409.5}
            y2={160}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={388.16}
            y1={194.02}
            x2={375.42}
            y2={168.93}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={356.89}
            y1={198.59}
            x2={345.82}
            y2={176.7}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={328.48}
            y1={202.73}
            x2={319.92}
            y2={183.5}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={306.92}
            y1={205.87}
            x2={299.06}
            y2={188.96}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <line
            x1={281.55}
            y1={193.55}
            x2={288.62}
            y2={208.54}
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="375.42 168.93 409.5 160 396.92 135.08 363.59 145.68"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="345.82 176.7 375.42 168.93 363.59 145.68 334.77 154.84"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="319.92 183.5 345.82 176.7 334.77 154.84 310.58 162.53"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="299.06 188.96 319.92 183.5 310.58 162.53 289.85 169.13"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polygon
            points="289.85 169.13 272.61 174.61 281.55 193.55 299.06 188.96 289.85 169.13"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="440.22 220.86 424.04 188.79 388.16 194.02"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="401.79 220.86 388.16 194.02 356.89 198.59"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="368.17 220.86 356.89 198.59 328.48 202.73"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="336.56 220.86 328.48 202.73 306.92 205.87"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <polyline
            points="313.87 220.86 306.92 205.87 288.62 208.54 294.42 220.86"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <path
            d="M234.25,237.68,46.82,227.89,30.64,250H230a19.68,19.68,0,0,1,2.46-9.57c.27-.5.56-1,.87-1.43.15-.23.3-.45.46-.67Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
          <path
            d="M323.56,250H270a19.91,19.91,0,0,1-4.27,12.32l187.43,9.79L469.36,250H323.56Z"
            transform="translate(-29.14 -29.14)"
            style={{
              fill: "none",
              stroke: "#000",
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeWidth: 3,
            }}
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Windmill;
