import * as React from "react";
const Pacman = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 372.56 372.56"
    className="windy"
    {...props}
  >
    <title>{"pacman"}</title>
    <g id="Frame1">
      <path
        d="M250,63.72c102.88,0,186.28,83.4,186.28,186.28S352.88,436.28,250,436.28,63.72,352.88,63.72,250,147.12,63.72,250,63.72Zm5.63,100.21a36.38,36.38,0,1,0-36.38,36.38A36.38,36.38,0,0,0,255.63,163.93Z"
        transform="translate(-63.72 -63.72)"
        style={{
          fill: "#ffed5d",
        }}
      />
      <circle cx={155.53} cy={100.21} r={36.38} />
    </g>
    <g id="Frame2">
      <path
        d="M416.64,166.68,250,250l166.64,83.32A186.29,186.29,0,0,1,250,436.28c-102.88,0-186.28-83.4-186.28-186.28S147.12,63.72,250,63.72A186.29,186.29,0,0,1,416.64,166.68Zm-161-2.75a36.38,36.38,0,1,0-36.38,36.38A36.38,36.38,0,0,0,255.63,163.93Z"
        transform="translate(-63.72 -63.72)"
        style={{
          fill: "#ffed5d",
        }}
      />
      <circle cx={155.53} cy={100.21} r={36.38} />
    </g>
    <g id="Frame3">
      <path
        d="M333.62,415.83l1.23,0A185.42,185.42,0,0,1,250,436.28c-102.88,0-186.28-83.4-186.28-186.28S147.12,63.72,250,63.72a185.52,185.52,0,0,1,83.26,19.61L250,250Zm-78-251.9a36.38,36.38,0,1,0-36.38,36.38A36.38,36.38,0,0,0,255.63,163.93Z"
        transform="translate(-63.72 -63.72)"
        style={{
          fill: "#ffed5d",
        }}
      />
      <circle cx={155.53} cy={100.21} r={36.38} />
    </g>
  </svg>
);
export default Pacman;
