import React, { useState} from "react";
import { QRCodeSVG } from "qrcode.react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './QrGen.css';
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const QrGen = () => {
  const [url, setUrl] = useState("");
  const [imageFormat, setImageFormat] = useState("");
  const [fgColor, setFgColor] = useState("#000000"); // Default foreground
  const [bgColor, setBgColor] = useState("#ffffff"); // Default background
  const [contrastErrorShown, setContrastErrorShown] = useState(false);

  const getLuminance = (color) => {
    const rgb = color.startsWith("#")
      ? color.length === 7
        ? color.slice(1).match(/.{2}/g).map((x) => parseInt(x, 16) / 255)
        : [0, 0, 0]
      : [0, 0, 0];
  
    const [r, g, b] = rgb.map((val) =>
      val <= 0.03928 ? val / 12.92 : Math.pow((val + 0.055) / 1.055, 2.4)
    );
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };
  
  const getContrastRatio = (color1, color2) => {
    const lum1 = getLuminance(color1);
    const lum2 = getLuminance(color2);
    const brightest = Math.max(lum1, lum2);
    const darkest = Math.min(lum1, lum2);
  
    if (darkest === 0) return 100;
  
    return (brightest + 0.05) / (darkest + 0.05);
  };

  const validateContrast = () => {
    const contrastRatio = getContrastRatio(fgColor, bgColor);
  
    if (contrastRatio < 2) {
      if (!contrastErrorShown) {
        toast.warn("Low contrast ratio", { 
          autoClose: 2000, 
          className: 'custom-toast custom-toast-warning2' 
        });
        setContrastErrorShown(true);
      }
    } else {
      if (contrastErrorShown) {
        setContrastErrorShown(false);
      }
    }
  };

  const handleInputChange = (e) => {
    setUrl(e.target.value);
  };

  const handleFormatChange = (e) => {
    setImageFormat(e.target.value);
  };

  const downloadQR = () => {
    if (!imageFormat) {
      return;
    }

    validateContrast();

    const svgElement = document.getElementById("qr-code");
    const svgData = new XMLSerializer().serializeToString(svgElement);

    if (imageFormat === "svg") {
      const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      const svgUrl = URL.createObjectURL(svgBlob);
      triggerDownload(svgUrl, "qr-code.svg");
    } else {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const svgUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgData)}`;
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.fillStyle = bgColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0);

        const imageType = `image/${imageFormat}`;
        const imgUrl = canvas.toDataURL(imageType);
        triggerDownload(imgUrl, `qr-code.${imageFormat}`);
      };

      img.src = svgUrl;
    }
  };

  const triggerDownload = (url, filename) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = filename;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className="qr-section">
              <Helmet>
                <title>Nisal Herath - QR Generator </title>
                <meta name="description" content="Web site created by Nisal Herath, showcasing projects and portfolio." />
                <meta name="keywords" content="Nisal Herath, portfolio, web development, automation, React, Python, developer, SLIIT, Sri Lanka" />
                <meta name="author" content="Nisal Herath" />
                <meta property="og:title" content="Nisal Herath - Developer & Automation Specialist" />
                <meta property="og:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
                <meta property="og:image" content="https://web.facebook.com/photo?fbid=1576640353092218&set=a.112999039456364" />
                <meta property="og:url" content="https://nisal.lk" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Nisal Herath - Developer & Automation Specialist" />
                <meta name="twitter:description" content="Explore the personal website of Nisal Herath, a developer specializing in web applications and automation." />
                <meta name="twitter:image" content="https://x.com/ArtNisal/photo" />
                <meta name="robots" content="index, follow" />
              </Helmet>
    <div className="qr-gen-container">
                  <Link to="/" className="backhome3" style={{ textDecoration: "none", color: "inherit" }}>
                    <div className="arrowhead2">
                      <div className="arrowline12"></div>
                      <div className="arrowline22"></div>
                      <div className="arrowmainline2"></div>
                    </div>
                    Back Home
                  </Link>

        <motion.div
                              className="qr-main"
                              initial={{ opacity: 0, y: -50 }}
                              whileInView={{ opacity: 1, y: 0 }}
                              viewport={{ once: true, amount: 0.1 }}
                              transition={{ duration: 0.6 }}
                            >
      <h2 className="qr-gen-heading">QR Code Generator</h2>

      <div className="qr-gen-input-container">
        <input
          className="qr-gen-input"
          type="text"
          placeholder="Enter URL or Text"
          value={url}
          onChange={handleInputChange}
        />
      </div>

<div className="qr-grid">
    <div className="qr-settings">
        <h2 className="qr-settings-header">Settings</h2>
      <div className="qr-gen-color-picker-container">
      <div className="qr-gen-color-picker">
        <input
          id="fg-color"
          type="color"
          value={fgColor}
          onChange={(e) => {
            setFgColor(e.target.value);
            validateContrast();
          }}
        />
        <label htmlFor="fg-color">Pattern Color</label>
      </div>

      <div className="qr-gen-color-picker">
        <input
          id="bg-color"
          type="color"
          value={bgColor}
          onChange={(e) => {
            setBgColor(e.target.value);
            validateContrast();
          }}
        />
        <label htmlFor="bg-color">Background Color</label>
      </div>
      </div>
      </div>


      <div className="qr-gen-output">
      <h2 className="qr-settings-header">Output</h2>
  {url ? (
    <>
      <QRCodeSVG
       className="qr-code"
        id="qr-code"
        value={url}
        size={200}
        level="H"
        bgColor={bgColor}
        fgColor={fgColor}
      />
      <br />

      <div className="qr-button-grid">
      <select
        className="qr-gen-format-selector"
        value={imageFormat}
        onChange={handleFormatChange}
      >
        <option value="">Select Image Format</option>
        <option value="svg">SVG</option>
        <option value="png">PNG</option>
        <option value="jpeg">JPEG</option>
      </select>
      <br />
      <button
        className="qr-gen-download-button"
        onClick={downloadQR}
        disabled={!imageFormat}
      >
        Download QR Code
      </button>
      </div>
    </>
  ) : (
    <h2 className="please-enter-url">Please enter a URL or a Text to Generate.</h2>
  )}
</div>
</div>
      <ToastContainer className="custom-toast-container2" />
      </motion.div>
    </div>
    </div>
  );
};

export default QrGen;
