import React, { memo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import './Experience.css';

const EverydayPost = ({
  everydayphone,
  currentMessage,
  messages,
  showReply,
  showImage,
}) => {
  const currentMessageData = messages[currentMessage - 1] || {};

  return (
    <div
      className="everydayphone"
      alt="iphone8"
      data-tooltip-id="everyday-tooltip"
      data-tooltip-content={everydayphone.alt}
    >
      {/* Tooltip */}
      <ReactTooltip
        id="everyday-tooltip"
        effect="solid"
        place="top"
        className="custom-tooltip"
        clickable
        delayShow={100}
        delayHide={100}
        positionStrategy="fixed"
        resizeObserver
        closeOnScroll
        arrowColor="rgba(31, 22, 83, 0.7)"
      />

      {/* Phone Elements */}
      <div className="phonebtn1"></div>
      <div className="phonespeaker1"></div>
      <div className="phonecamera1"></div>

      {/* Message Rendering */}
      {currentMessage > 0 && (
        <>
          {/* Sender Message */}
          <div
            className={`sendermsg smsg${currentMessage} fade-in`}
            key={`sendermsg-${currentMessage}`}
          >
            <p>{currentMessageData.text}</p>
          </div>

          {/* Receiver Message */}
          {showReply && (
            <div
              className={`recievermsg rmsg${currentMessage} fade-in`}
              key={`receivermsg-${currentMessage}`}
            >
              <p>{currentMessageData.reply}</p>
              {showImage && currentMessageData.replyimg && (
                <img
                  src={currentMessageData.replyimg}
                  alt="Reply Visual"
                  className={`replyimg rimg${currentMessage} fade-in`}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default memo(EverydayPost);
