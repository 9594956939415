import * as React from "react";

const Up = (props) => (
  <svg
    id="up"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 278.39 382.82"
    {...props}
    className="upbtn"
  >
    <title>{"Go Up"}</title>
    <path
      className="svg-path"
      d="M126.23,88.48a394.33,394.33,0,0,0-9.91,104.08c1.11,27.44,3,59.3,18.89,82.84,7.25,10.72,17.5,19.61,30.34,22.69,15,3.58,30.46-.53,42.81-9.39,25.1-18,39.16-48.47,47.17-77.5,9.3-33.71,10.75-69.17,10.55-103.94-.07-13.18-20.68-16.76-23.57-3.19a441,441,0,0,0-9.62,106.75,421.63,421.63,0,0,0,5.22,53.78c2.45,14.89,7.39,31.36,19.52,41.31,14.38,11.81,33.88,14,48.82,2.12L286,299.55q7.11,75.48,14.21,151h24c-7.5-78.15-15.49-156.27-22.48-234.46-1.31-14.59-2.47-29.63,2.77-43.2a28.33,28.33,0,0,1,23.77-18.37c10.18-1.15,22.1,2.25,29.7,9.44,9.52,9,13,21.76,12.59,34.53-1.12,31.93-29.71,54.35-59.35,63-14.8,4.3-8.51,27.46,6.38,23.14,33.43-9.72,65.63-33.15,74.48-68.41,8.54-34-6.15-72.52-41.58-83.2-15.38-4.63-33.44-3.31-47.21,5.35C287.78,148,280,164.78,277.7,182.38c-2.43,18.45.31,37.09,2.07,55.47L286,303.14l14.14,147.38c.61,6.45,5.07,12,12,12,6,0,12.61-5.51,12-12Q317.06,375,310,299.55c-1-10.89-12.14-15.15-20.48-8.49.17-.14-.91.45-1.64.68a20.47,20.47,0,0,1-2.55.59c1-.21.74-.25-.77-.11a20.73,20.73,0,0,1-7.11-1.74c-5-2.51-8.7-7.16-11.23-13-3.15-7.25-4.39-15.47-5.53-23.24-1.38-9.34-2.3-18.76-3-28.18a415.4,415.4,0,0,1,8-115.65l-23.58-3.19c.16,27.14-.53,54.56-5.84,81.26-4.92,24.68-13.52,51.74-30.75,70.78-7.46,8.25-17.56,16-28,16.18a17.94,17.94,0,0,1-5.53-.53,23,23,0,0,1-5.23-1.86,28.26,28.26,0,0,1-4.53-2.93c-.92-.8-1.8-1.64-2.65-2.52-.32-.32-3.05-3.48-2.13-2.3-6.54-8.42-9.34-16.84-11.86-27.23-3.15-13-4.19-26.48-4.93-39.8a369.36,369.36,0,0,1,8.78-103.45c1.45-6.31-1.95-13-8.39-14.76-6.05-1.66-13.3,2-14.76,8.38Z"
      transform="translate(-116.01 -79.7)"
    />
  </svg>
);

export default Up;