import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import './Astro.css';
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation'; 
import { Navigation,Pagination,Autoplay } from 'swiper/modules';

const Astro = () => {
  const [moonPhaseImage, setMoonPhaseImage] = useState(null);
  const [nextSevenDays, setNextSevenDays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [country, setCountry] = useState(null);
  const isRequestInProgress = useRef(false);


  const getLocationFromGeoJS = async () => {
    try {
      const response = await axios.get('https://get.geojs.io/v1/ip/geo.json');
      const { country, latitude, longitude } = response.data;

      setCountry(country);
      setLocation({
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
      });
    } catch (error) {
      console.error('Failed to fetch location from GeoJS:', error);
      setError('Failed to fetch location');
    }
  };

  const fetchMoonPhaseData = useCallback(async () => {
    if (isRequestInProgress.current) return;

    isRequestInProgress.current = true;

    try {
      setLoading(true);
      const response = await axios.post('/.netlify/functions/moonPhase', {
        latitude: location.latitude,
        longitude: location.longitude,
      });
      const { imageUrls } = response.data;

      setMoonPhaseImage(imageUrls[0]);
      setNextSevenDays(imageUrls.slice(1).map((url, index) => ({
        imageUrl: url,
        alt: `Moon Phase Day ${index + 2}`,
      })));
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch moon phase data');
      setLoading(false);
    } finally {
      isRequestInProgress.current = false;
    }
  }, [location.latitude, location.longitude]);

  useEffect(() => {
    if (location.latitude && location.longitude) {
      fetchMoonPhaseData();
    }
  }, [location, fetchMoonPhaseData]);

  useEffect(() => {
    getLocationFromGeoJS();
  }, []);

  if (loading) {
    return <div className="loading-container-moon"><div className="loading123"></div></div>;
  }

  if (error) {
    return <div className="astro-error">{error}</div>;
  }

  return (
    <div className="astro-section">
      <Helmet>
        <title>Nisal Herath - Moon Phase Today </title>
      </Helmet>
      <div className="astro-button">
        <Link to="/" className="backhome3" style={{ textDecoration: "none", color: "inherit" }}>
          <div className="arrowhead2">
            <div className="arrowline12"></div>
            <div className="arrowline22"></div>
            <div className="arrowmainline2"></div>
          </div>
          Back Home
        </Link>
      </div>
      <div className="astro-grid">
      <motion.div
        className="astro-moving"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
      >
        <h1 className="astro-header">Today's Moon Phase</h1>
        <p className="astro-location">in {country}</p>
        {moonPhaseImage && (
          <div>
            <img src={moonPhaseImage} alt="Moon Phase" className="astro-image" />
          </div>
        )}
      </motion.div>

      <motion.div
        className="astro-sevendays"
        initial={{ opacity: 0, y: -50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.1 }}
        transition={{ duration: 0.6 }}
      >
        <h1 className="astro-header">Next 7 Days of Moon</h1>
        <p className="astro-location">in {country}</p>
        <Swiper
  slidesPerView="auto"
  spaceBetween={0}
  loop={true}
  grabCursor={true}
  centeredSlides={true}
  navigation={{
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  }}
  pagination={{
    clickable: true,
  }}
  autoplay={{
    delay: 3000,
    disableOnInteraction: false,
  }}
  modules={[Navigation, Pagination, Autoplay]}
  className="sevenday-carousel"
  style={{ width: '100%' }}
>
  {nextSevenDays.map((day, index) => (
    <SwiperSlide key={index}>
      <img
        src={day.imageUrl}
        alt={day.alt || `Moon Phase ${index + 1}`}
        className="moon-carousel-image"
      />
    </SwiperSlide>
  ))}
  <div className="swiper-button-next"></div>
  <div className="swiper-button-prev"></div>
</Swiper>


      </motion.div>
      </div>
      <div className="poweredadvice">
          <p>
            Powered by{' '}
            <a href="https://astronomyapi.com/" target="_blank" rel="noopener noreferrer">
              AstronomyAPI
            </a>
          </p>
        </div>
    </div>
  );
};

export default Astro;
